<h1 class="page-title">{{ 'sign_page.title' | translate }}</h1>
<div fxLayout.gt-md="row">
  <div fxFlex.gt-md="19.9"></div>
  <p class="center" [innerHTML]="'sign_page.swa_description' | translate"></p>
  <div fxFlex.gt-md="19.9"></div>
</div>

<div class="cards-container"
     fxLayout.gt-sm="row">

  <!------------------------------------------  card connexion -------------------------------------------->
  <div fxFlex.gt-sm></div>
  <antai-sign-in-card [color]="'shaded blue'" fxFlex.gt-sm="40"></antai-sign-in-card>
  <div fxFlex.gt-sm="4"></div>

  <!------------------------------------------  card création de compte -------------------------------------------->

  <antai-card fxFlex.gt-sm="40" [value]="{
        title: 'sign_page.sign_up.title',
        color: 'shaded white',
        isInline: true,
        headerAlign: 'center',
        footerWithBtn: {
          centerBtn: {
            color: 'blue',
            label: 'sign_page.sign_up.submit',
            clickEvent: {event: signUpBtnClickEvent}
          }
        },
        sectionsSizes: {header: 15, body: 0, footer: 11}
        }">
    <div id="sign-up-card-body">
      <div class="center">
        <img src="assets/icons/gear@3x.png" alt="{{ 'sign_page.sign_up.logo_alt' | translate }}">
      </div>
      <div id="sign-up-info-detail">
        <p>{{ 'sign_page.sign_up.detail' | translate }}</p>
      </div>
    </div>
  </antai-card>
  <div fxFlex.gt-sm></div>
</div>
