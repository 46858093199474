import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map, switchMap } from 'rxjs/operators';
import { OverlaysService } from '../../shared/services/overlays.service';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';

// Message d'erreur lorsque le back ne réponds pas
// Charger avant la librairie ngx-translate
const ERROR_MESSAGE_500 = 'Il y a un souci technique. Veuillez réessayer ultérieurement';
const ERROR_CODE_401 = 'MSG-PNUM-CONNEX-15';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  hasRefreshTokenInprogress = false;

  constructor(private overlaysService: OverlaysService,
              private authService: AuthService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        return event;
      }),
      catchError((httpErrorResponse: HttpErrorResponse) => {
        let error = httpErrorResponse.error;
        if (error && !error.label) {
          if (typeof error === 'string') {
            try {
              error = JSON.parse(httpErrorResponse.error);
            } catch (e) {
              error = {label: ERROR_MESSAGE_500};
            }
            if (!error.label) {
              error = {label: ERROR_MESSAGE_500};
            }
            httpErrorResponse = this.createNewHttpErrorResponse(httpErrorResponse, error);
          }
        }
        if (httpErrorResponse.status === 500) {
          error.label = '';
          httpErrorResponse = this.createNewHttpErrorResponse(httpErrorResponse, error);
          this.overlaysService.addSnackBar(ERROR_MESSAGE_500, true);
        } else if (httpErrorResponse.status === 401 && error.code === ERROR_CODE_401) {
          return this.handleUnauthorized(request, next);
        }
        return throwError(httpErrorResponse);
      })
    );
  }

  private createNewHttpErrorResponse(currentHttpErrorResponse: HttpErrorResponse, errorObject): HttpErrorResponse {
    return new HttpErrorResponse({
      error: errorObject,
      headers: currentHttpErrorResponse.headers,
      status: currentHttpErrorResponse.status,
      statusText: currentHttpErrorResponse.statusText,
    });
  }

  /**
   * Cette fonction permet de gérer les erreurs Unauthorized (401) retourner par la requête
   * @param request requête originale
   * @param next evénement Http
   * @return  objet observable d'une requête modifiée OU une erreur
   */
  private handleUnauthorized(request: HttpRequest<any>,
                             next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.hasRefreshTokenInprogress) {
      if (this.authService.currentUserValue.rafraichissementToken) {
        this.hasRefreshTokenInprogress = true;
        // Rafraîchir le token
        return this.authService.refreshToken().pipe(
          // Session expirée
          catchError((errorResponse: HttpErrorResponse) => {

            this.authService.logout(true);
            return throwError(errorResponse);
          }),
          finalize(() => {
            this.hasRefreshTokenInprogress = false;
          }),
          switchMap(() => next.handle(request))
        );
      }
    }
  }
}
