import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, NG_VALUE_ACCESSOR, ValidatorFn, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { finalize, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AddressApiProperty } from './address-api-property.model';

@Component({
  selector: 'antai-address-api-autocomplete',
  templateUrl: './address-api-autocomplete.component.html',
  styleUrls: ['./address-api-autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddressApiAutocompleteComponent),
      multi: true
    }
  ]
})
export class AddressApiAutocompleteComponent implements ControlValueAccessor, OnInit {

  formControl: FormControl;
  formOptions: AddressApiProperty[] = [];
  myAddressEntered: string;
  noResultLabel: string;
  hasSearchInprogress = false;

  @Input() type: string;
  @Input() propDisplayed: string;
  @Input() propDisplayedMore: string;
  @Input() placeholder: string;
  @Input() minLength = 0;
  @Input() maxLength: number;
  @Input() searchMinLength = 3;
  @Input() isRequired = false;
  @Input() patternControl: { regExp: RegExp, errorLabel: string };
  // Identifiant utilisé pour identifier l'input dans le cadre des tests automatisés
  @Input() idInput: string;
  @Output() addressApiValueChange = new EventEmitter<AddressApiProperty>();

  constructor(private formBuilder: FormBuilder,
              private translateService: TranslateService,
              private http: HttpClient) {

    // Récupération des libélés pour le choix de concerver sa saisie
    translateService.get([
      'address_api_autocomplete.my_address',
      'address_api_autocomplete.no_result'
    ]).pipe(map((valuesObject: Object) => Object.values(valuesObject)))
      .subscribe(([myValue, noResult]) => {
      this.myAddressEntered = myValue;
      this.noResultLabel = noResult;
    });
  }

  ngOnInit(): void {
    const validators = new Array<ValidatorFn>();
    if (this.isRequired) { validators.push(Validators.required); }
    if (this.patternControl) { validators.push(Validators.pattern(this.patternControl.regExp)); }
    this.formControl = new FormControl('', validators);
    this.formControl.valueChanges
      .subscribe((value: string) => {
        // Notifie au parent que les valeurs des champs adresse sont vides, le composant mat-autocomplete n'a pas d'event pour gérer ce cas
        if (!value) {
          this.addressApiValueChange.emit(new AddressApiProperty());
        }
        if (value?.length && value.length >= this.searchMinLength && !this.hasSearchInprogress) {
          this.searchAdress(value).subscribe((r: AddressApiProperty[]) => {
            this.formOptions = r;
          });
        } else {
          this.formOptions = [];
        }
      });
  }

  /**
   * Sélection de la valeur dans l'input.
   * @param selectedValue valeur sélectionnée
   */
  onSelectValue(selectedValue: any) {
    if (selectedValue) {
      if (typeof selectedValue === 'string') {
        const formatedValue = new AddressApiProperty();
        formatedValue[this.propDisplayed] = selectedValue;
        selectedValue = formatedValue;
      } else {
        // hack bug lors de l'update du FormControl
        setTimeout(() => {
          if (selectedValue.isEnteredOption) {
            this.addressApiValueChange.emit(selectedValue.value);
          } else {
            this.addressApiValueChange.emit(selectedValue);
          }
        }, 0);
      }
      return selectedValue[this.propDisplayed] || selectedValue['value'];
    }
  }

  // Appel à l'api adresse
  searchAdress(value: string): Observable<AddressApiProperty[]> {

    this.hasSearchInprogress = true;

    const params = {
      q: value,
      autocomplete: '1'
    };

    if (this.type) {
      Object.assign(params, {
        type: this.type
      });
    }

    return this.http.get('https://api-adresse.data.gouv.fr/search/', {params: params}).pipe(
      finalize(() => {
        this.hasSearchInprogress = false;
      }),
      map((r: any) => r.features.map(a => a.properties) as AddressApiProperty[])
    );
  }

  propagateChange: any = () => {};

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {}

  writeValue(value: any) {
    this.formControl.setValue(value, { emitEvent: false });
  }

  setDisabledState(isDisabled: boolean) {
    if (isDisabled) {
      this.formControl.disable();
    } else {
      this.formControl.enable();
    }
  }
}
