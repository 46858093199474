import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable()
export class AuthJwtInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {
  }

  // Interception des appelles http
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // ajout de l'header avec le jwt si l'utilisateur et logué et si c'est un appel à l'api.
    // if (request.method === 'GET' && request.url.includes('/api/messagerie/conversations')) {
    //   const arr: ListeConversationDto = {
    //     entities: [{
    //       dernierMessage: 'string',
    //       id: 0,
    //       objet: 'string',
    //       service: TypeService.Fps,
    //       sujet: Sujet.Annulation,
    //       auteur: 'Jemas',
    //       dateDernierMessage: '20:20:20',
    //       isLu: false
    //     }],
    //     nombrePages: 1,
    //     nombreParPage: 20,
    //     nombreTotal: 1,
    //     pageCourante: 1,
    //   };
    //   return of(new HttpResponse({status: 200, body: arr}));
    // }if (request.method === 'GET' && request.url.includes('/api/messagerie/messages/0')) {
    //   const arr: ConversationDto = {
    //     messages: [{
    //       messageLu: true,
    //       dateCreation: '01012021',
    //       identiteAuteur: 'Jean',
    //       isExpediteur: true,
    //       message: 'Ceci est un test'
    //     },
    //       {
    //         messageLu: true,
    //         dateCreation: '01012021',
    //         identiteAuteur: 'Paul',
    //         isExpediteur: false,
    //         message: 'Ceci est un test 2 fhf fhziefhdhf fsze fzefhzefio dsfsdfhsdfdf dsfsdfsdfsd fsdfsdfsfsd fsdf sdfsd fsd f sdfs df sdf sdf f  f  sdf sdf sd fsd fs df sdf sd fsd f sdf sdf s dffq f sdf d fs dfsd f sdsd  ssd fsd  sdsd sd  dd sfsd fsd sd sdf df ssdf  sdfsdf  dssd f f sdf sdfsd sdfsdf sdfzefzert zertfsdfdg grtrg trg grtgtr htrhtrh rthtrh h trh trhtr trh thg jtrtk trk gtrjgfdddddddd dfgdfgdg gserg erg r gd fgf gzefzef f fzerfer ff ererferferf erferf er erferferf eferferfer f erferf erf test'
    //       },
    //       {
    //         messageLu: true,
    //         dateCreation: '01012021',
    //         identiteAuteur: 'Paul',
    //         isExpediteur: false,
    //         message: 'Ceci est un test 2'
    //       },
    //       {
    //         messageLu: true,
    //         dateCreation: '01012021',
    //         identiteAuteur: 'Jean',
    //         isExpediteur: true,
    //         message: 'Ceci est un test'
    //       },
    //       {
    //         messageLu: true,
    //         dateCreation: '01012021',
    //         identiteAuteur: 'Paul',
    //         isExpediteur: false,
    //         message: 'Ceci est un test 2'
    //       },
    //       {
    //         messageLu: true,
    //         dateCreation: '01012021',
    //         identiteAuteur: 'Jean',
    //         isExpediteur: true,
    //         message: 'Ceci est un test'
    //       },
    //       {
    //         messageLu: true,
    //         dateCreation: '01012021',
    //         identiteAuteur: 'Jean',
    //         isExpediteur: true,
    //         message: 'Ceci est un test'
    //       },
    //       {
    //         messageLu: true,
    //         dateCreation: '01012021',
    //         identiteAuteur: 'Paul',
    //         isExpediteur: false,
    //         message: 'Ceci est un test 2'
    //       },
    //       {
    //         messageLu: true,
    //         dateCreation: '01012021',
    //         identiteAuteur: 'Paul',
    //         isExpediteur: false,
    //         message: 'Ceci est un test 2'
    //       },
    //       {
    //         messageLu: true,
    //         dateCreation: '01012021',
    //         identiteAuteur: 'Paul',
    //         isExpediteur: false,
    //         message: 'Ceci est un test 2'
    //       },
    //       {
    //         messageLu: true,
    //         dateCreation: '01012021',
    //         identiteAuteur: 'Jean',
    //         isExpediteur: true,
    //         message: 'Ceci est un test'
    //       },
    //       {
    //         messageLu: true,
    //         dateCreation: '01012021',
    //         identiteAuteur: 'Paul',
    //         isExpediteur: false,
    //         message: 'Ceci est un test 2'
    //       },
    //       {
    //         messageLu: true,
    //         dateCreation: '01012021',
    //         identiteAuteur: 'Paul',
    //         isExpediteur: false,
    //         message: 'Ceci est un test 2'
    //       },
    //       {
    //         messageLu: true,
    //         dateCreation: '01012021',
    //         identiteAuteur: 'Jean',
    //         isExpediteur: true,
    //         message: 'Ceci est un test'
    //       },
    //       {
    //         messageLu: true,
    //         dateCreation: '01012021',
    //         identiteAuteur: 'Jean',
    //         isExpediteur: true,
    //         message: 'Ceci est un test'
    //       },
    //       {
    //         messageLu: true,
    //         dateCreation: '01012021',
    //         identiteAuteur: 'Paul',
    //         isExpediteur: false,
    //         message: 'Ceci est un test 2'
    //       },
    //       {
    //         messageLu: true,
    //         dateCreation: '01012021',
    //         identiteAuteur: 'Paul',
    //         isExpediteur: false,
    //         message: 'Ceci est un test 2'
    //       },
    //       {
    //         messageLu: true,
    //         dateCreation: '01012021',
    //         identiteAuteur: 'Jean',
    //         isExpediteur: true,
    //         message: 'Ceci est un test'
    //       },
    //       {
    //         messageLu: true,
    //         dateCreation: '01012021',
    //         identiteAuteur: 'Paul',
    //         isExpediteur: false,
    //         message: 'Ceci est un test 2'
    //       },
    //       {
    //         messageLu: true,
    //         dateCreation: '01012021',
    //         identiteAuteur: 'Jean',
    //         isExpediteur: true,
    //         message: 'Ceci est un test'
    //       },
    //       {
    //         messageLu: true,
    //         dateCreation: '01012021',
    //         identiteAuteur: 'Paul',
    //         isExpediteur: false,
    //         message: 'Ceci est un test 2'
    //       },
    //       {
    //         messageLu: true,
    //         dateCreation: '01012021',
    //         identiteAuteur: 'Jean',
    //         isExpediteur: true,
    //         message: 'Ceci est un test'
    //       },
    //       {
    //         messageLu: true,
    //         dateCreation: '01012021',
    //         identiteAuteur: 'Jean',
    //         isExpediteur: true,
    //         message: 'Ceci est un test'
    //       },
    //       {
    //         messageLu: true,
    //         dateCreation: '01012021',
    //         identiteAuteur: 'Paul',
    //         isExpediteur: false,
    //         message: 'Ceci est un test 2'
    //       },
    //       {
    //         messageLu: true,
    //         dateCreation: '01012021',
    //         identiteAuteur: 'Jean',
    //         isExpediteur: true,
    //         message: 'Ceci est un test'
    //       },
    //       {
    //         messageLu: true,
    //         dateCreation: '01012021',
    //         identiteAuteur: 'Paul',
    //         isExpediteur: false,
    //         message: 'Ceci est un test 2'
    //       }],
    //     objet: 'string',
    //     service: TypeService.Fps,
    //     sujet: Sujet.Annulation
    //   };
    //   return of(new HttpResponse({status: 200, body: arr}));
    // }
    // TODO a supprimer
    // if (request.method === 'GET' && request.url === '/api/gestion-acces/checkFF/FF_MESSAGERIE') {
    //
    //   return of(new HttpResponse({status: 200, body: {'value': true}}));
    // }
    // if (request.method === 'GET' && request.url === '/api/gestion-acces/certificat/fps') {
    //   let c : { 'certificats'?: Array<CertificatFpsDto>, 'dateFuturePersonnalisation'?: string }
    //   = {certificats: [{
    //       idCertificat: 0,
    //       numeroCertificat: '78555',
    //       etat: 'WAITING',
    //       nom: 'cert1',
    //       dateFinValidite: '20/12/2020'
    //     },
    //       {
    //         idCertificat: 1,
    //         numeroCertificat: '78555',
    //         etat: 'WAITING',
    //         nom: 'cert2'
    //       }],
    //   dateFuturePersonnalisation: '20/12/2030'};
    //   return of(new HttpResponse({status: 200, body: c}));
    // }
    // if (request.method === 'GET' && request.url === '/api/gestion-acces/certificat/38103128500491/1/fps') {
    //   return of(new HttpResponse({status: 200, body: 'rerlkgjelkgjelkrgjelkrjglkejrkkl'}));
    // }
    // let partner: ConventionPVeDto;
    // if (request.url === '/api/gestion-acces/convention/pve/2/prestataire') {
    //   const arr: ChangementPrestatairePVeDto = {
    //       codeService: 'string',
    //       datePrevisionnelleChangement: '20/10/2030',
    //       emailContactSecondaire: 'string@test.com',
    //       id: 2,
    //       nomContactSecondaire: 'string',
    //       nomFichier: 'string',
    //       prestataireEntrant: {
    //         id: 1,
    //         nomPrestataire: 'P1',
    //       },
    //       prestataireSortant: {
    //         id: 2,
    //         nomPrestataire: 'P2'
    //       },
    //       statut: 'VALIDE',
    //       telephoneContactSecondaire: '0299096666'
    //     };
    //   return of(new HttpResponse({status: 200, body: arr}));
    // }
    // if (request.url === '/api/gestion-acces/convention/pve/typeUnite') {
    //   const arr: Array<{id: number, sigle, label: string}> = [
    //     {
    //       id: 0,
    //       sigle: 'PM',
    //       label: 'Police municipale'
    //     },
    //     {
    //       id: 1,
    //       sigle: 'PR',
    //       label: 'Police rurale'
    //     },
    //     {
    //       id: 2,
    //       sigle: 'ASVP',
    //       label: 'ASVP'
    //     }
    //   ];
    //   return of(new HttpResponse({status: 200, body: arr}));
    // }
    // if (request.url === '/api/gestion-acces/prestataire') {
    //   const arr: PrestataireDto[] = [
    //     {
    //       id: 0,
    //       nomPrestataire: 'P0'
    //     },
    //     {
    //       id: 1,
    //       nomPrestataire: 'P1'
    //     },
    //     {
    //       id: 2,
    //       nomPrestataire: 'P2'
    //     }
    //   ];
    //   return of(new HttpResponse({status: 200, body: arr}));
    // }
    // if (request.method === 'POST' && request.url === '/api/gestion-acces/convention/pve') {
    //   partner = {
    //     gradeCorrespondant: 'Commandant',
    //     informationsComplementaires: 'string',
    //     nomCourtSite: 'undefined',
    //     nombreAgentsVerbalisateurs: 5,
    //     nombreCartesPuces: 8,
    //     nombreCertificatsVPN: 5,
    //     nombrePDA: 2,
    //     nombreTablettes: 5,
    //     portableCorrespondant: '0669887744',
    //     prestataire: {id: 0, nomPrestataire: 'rr'},
    //     typeContactCorrespondant: {id: 0, label: 'PR'},
    //     typeUnite: {id: 0, label: 'PR'},
    //     adresseFacturation: 'Rue de Paris',
    //     civiliteCorrespondant: 'M',
    //     codePostalFacturation: '35000',
    //     commentaire: 'Comment',
    //     communeFacturation: 'Rennes',
    //     complementAdresseFacturation: '',
    //     dateCreation: 'string',
    //     dateFin: 'string',
    //     dateModification: 'string',
    //     dateValidation: 'string',
    //     engagement: true,
    //     id: 5,
    //     mailCorrespondant: 'lemail@orange.fr',
    //     mailFacturation: 'lemail@orange.fr',
    //     nomCorrespondant: 'Jean',
    //     nomFacturation: 'Jean',
    //     nomSite: 'PR Rennes',
    //     prenomCorrespondant: 'Paul',
    //     prenomFacturation: 'Paul',
    //     statut: 'A_TRAITER',
    //     telephoneCorrespondant: '0299887755',
    //     typeService: 'PVE',
    //   };
    //   return of(new HttpResponse({status: 200, body: partner}));
    // }
    // if (request.method === 'GET' && request.url === '/api/gestion-acces/convention/pve/5') {
    //   partner = {
    //     gradeCorrespondant: 'Commandant',
    //     informationsComplementaires: 'string',
    //     nomCourtSite: 'undefined',
    //     nombreAgentsVerbalisateurs: 5,
    //     nombreCartesPuces: 8,
    //     nombreCertificatsVPN: 5,
    //     nombrePDA: 2,
    //     nombreTablettes: 5,
    //     portableCorrespondant: '0669887744',
    //     prestataire: {id: 0, nomPrestataire: 'rr'},
    //     typeContactCorrespondant: {id: 0, label: 'PR'},
    //     typeUnite: {id: 0, label: 'PR'},
    //     adresseFacturation: 'Rue de Paris',
    //     civiliteCorrespondant: 'M',
    //     codePostalFacturation: '35000',
    //     commentaire: 'Comment',
    //     communeFacturation: 'Rennes',
    //     complementAdresseFacturation: '',
    //     dateCreation: 'string',
    //     dateFin: 'string',
    //     dateModification: 'string',
    //     dateValidation: 'string',
    //     engagement: true,
    //     id: 5,
    //     mailCorrespondant: 'lemail@orange.fr',
    //     mailFacturation: 'lemail@orange.fr',
    //     nomCorrespondant: 'Jean',
    //     nomFacturation: 'Jean',
    //     nomSite: 'PR Rennes',
    //     prenomCorrespondant: 'Paul',
    //     prenomFacturation: 'Paul',
    //     statut: 'A_TRAITER',
    //     telephoneCorrespondant: '0299887755',
    //     typeService: 'PVE',
    //   };
    //   return of(new HttpResponse({status: 200, body: partner}));
    // } else if (request.url === '/api/gestion-acces/convention/pve/4') {
    //     partner = {
    //       gradeCorrespondant: 'GRade',
    //       informationsComplementaires: 'string',
    //       nomCourtSite: 'string',
    //       nombreAgentsVerbalisateurs: 5,
    //       nombreCartesPuces: 8,
    //       nombreCertificatsVPN: 5,
    //       nombrePDA: 2,
    //       nombreTablettes: 5,
    //       portableCorrespondant: 'string',
    //       prestataire: {id: 0, nomPrestataire: 'rr'},
    //       typeContactCorrespondant: {id: 0, label: 'PR'},
    //       typeUnite: {id: 0, label: 'PR'},
    //       adresseFacturation: 'string',
    //       civiliteCorrespondant: 'M',
    //       codePostalFacturation: 'string',
    //       commentaire: 'string',
    //       communeFacturation: 'string',
    //       complementAdresseFacturation: 'string',
    //       dateCreation: 'string',
    //       dateFin: 'string',
    //       dateModification: 'string',
    //       dateValidation: 'string',
    //       engagement: true,
    //       id: 4,
    //       mailCorrespondant: 'string@ss',
    //       mailFacturation: 'string',
    //       nomCorrespondant: 'string',
    //       nomFacturation: 'string',
    //       nomSite: 'string',
    //       prenomCorrespondant: 'string',
    //       prenomFacturation: 'string',
    //       statut: 'VALIDE',
    //       telephoneCorrespondant: 'string',
    //       typeService: 'PVE',
    //     };
    //   return of(new HttpResponse({status: 200, body: partner}));
    // }

    let user = this.authService.currentUserValue;
    const isLoggedIn = user && user.jwtToken;

    if (request.url === '/api/login') {
      request = request.clone({
        setHeaders: {
          Authorization: 'Basic ' + this.authService.loginCredential
        }
      });
    } else if (isLoggedIn && request.url.startsWith('/api')) {
      request = request.clone({
        setHeaders: {
          Authorization: user.jwtToken
        }
      });
    }

    // Interception des reponses http
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          const jwtToken = event.headers.get('Authorization');
          if (jwtToken) {
            user = event.body;
            user.jwtToken = jwtToken;
            this.authService.setCurrentUserValue(user);
          }
        }
        return event;
      })
    );
  }
}
