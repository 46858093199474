/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DocumentDto } from '../models/document-dto';
import { TypeDocumentEnumDto } from '../models/type-document-enum-dto';

@Injectable({
  providedIn: 'root',
})
export class GestionDesDocumentsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getDocument
   */
  static readonly GetDocumentPath = '/document/{id}';

  /**
   * Téléchargement d'une pièce justificative d'une entité par son id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocument$Response(params: {

    /**
     * Identifiant de la pièce justificative
     */
    id: number;
  }): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, GestionDesDocumentsService.GetDocumentPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'multipart/form-data'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Téléchargement d'une pièce justificative d'une entité par son id.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocument(params: {

    /**
     * Identifiant de la pièce justificative
     */
    id: number;
  }): Observable<Blob> {

    return this.getDocument$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation getDocuments
   */
  static readonly GetDocumentsPath = '/documents';

  /**
   * Récupération par l'Admin SGF des documents d'une entité par numéro d'identification et par type.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocuments()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocuments$Response(params: {

    /**
     * Numéro d&#x27;identification de l&#x27;entité
     */
    numeroIdentification: string;

    /**
     * Type de document
     */
    type: TypeDocumentEnumDto;
  }): Observable<StrictHttpResponse<Array<DocumentDto>>> {

    const rb = new RequestBuilder(this.rootUrl, GestionDesDocumentsService.GetDocumentsPath, 'get');
    if (params) {
      rb.query('numeroIdentification', params.numeroIdentification, {});
      rb.query('type', params.type, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DocumentDto>>;
      })
    );
  }

  /**
   * Récupération par l'Admin SGF des documents d'une entité par numéro d'identification et par type.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDocuments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocuments(params: {

    /**
     * Numéro d&#x27;identification de l&#x27;entité
     */
    numeroIdentification: string;

    /**
     * Type de document
     */
    type: TypeDocumentEnumDto;
  }): Observable<Array<DocumentDto>> {

    return this.getDocuments$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DocumentDto>>) => r.body as Array<DocumentDto>)
    );
  }

  /**
   * Path part for operation getDocumentsEntite
   */
  static readonly GetDocumentsEntitePath = '/document';

  /**
   * Récupération de la dernière version de chaque type de pièce justificative d'une entité.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocumentsEntite()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentsEntite$Response(params?: {

    /**
     * Numéro d&#x27;identification de l&#x27;entité, nécessaire dans le cas d&#x27;un appel par un administrateur
     */
    numeroIdentification?: string;
  }): Observable<StrictHttpResponse<Array<DocumentDto>>> {

    const rb = new RequestBuilder(this.rootUrl, GestionDesDocumentsService.GetDocumentsEntitePath, 'get');
    if (params) {
      rb.query('numeroIdentification', params.numeroIdentification, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DocumentDto>>;
      })
    );
  }

  /**
   * Récupération de la dernière version de chaque type de pièce justificative d'une entité.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDocumentsEntite$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentsEntite(params?: {

    /**
     * Numéro d&#x27;identification de l&#x27;entité, nécessaire dans le cas d&#x27;un appel par un administrateur
     */
    numeroIdentification?: string;
  }): Observable<Array<DocumentDto>> {

    return this.getDocumentsEntite$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DocumentDto>>) => r.body as Array<DocumentDto>)
    );
  }

  /**
   * Path part for operation updateDocument
   */
  static readonly UpdateDocumentPath = '/document';

  /**
   * Téléversement d'un document.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateDocument()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  updateDocument$Response(params: {
    numeroIdentification: string;
    type: 'EXTRAIT_KBIS' | 'ATTESTATION_INSCRIPTION_RCS' | 'AVIS_SITUATION_SIRET_SIREN' | 'ATTESTATION_ENREGISTREMENT' | 'MANDAT_REPRESENTANT_LEGAL' | 'ATTESTATION_GESTION' | 'CONVENTION' | 'AUTRE' | 'CONVENTION_PVE' | 'AVIS_INFORMATION' | 'RELEVE_INFRACTION' | 'ARTICLE_13_05_2011' | 'ARTICLE_21_06_2011';
    body?: { 'pieces': Blob }
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, GestionDesDocumentsService.UpdateDocumentPath, 'put');
    if (params) {
      rb.query('numeroIdentification', params.numeroIdentification, {});
      rb.query('type', params.type, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Téléversement d'un document.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateDocument$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  updateDocument(params: {
    numeroIdentification: string;
    type: 'EXTRAIT_KBIS' | 'ATTESTATION_INSCRIPTION_RCS' | 'AVIS_SITUATION_SIRET_SIREN' | 'ATTESTATION_ENREGISTREMENT' | 'MANDAT_REPRESENTANT_LEGAL' | 'ATTESTATION_GESTION' | 'CONVENTION' | 'AUTRE' | 'CONVENTION_PVE' | 'AVIS_INFORMATION' | 'RELEVE_INFRACTION' | 'ARTICLE_13_05_2011' | 'ARTICLE_21_06_2011';
    body?: { 'pieces': Blob }
  }): Observable<boolean> {

    return this.updateDocument$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation envoiDocuments
   */
  static readonly EnvoiDocumentsPath = '/document';

  /**
   * Ajout des pièces justificatives pour une entité.
   *
   * La méthode est accessible au utilisateur ayant le rôle gestionnaire de flotte ou gestionnaire de flotte admin et dont le compte est 'En attente de finalisation' ou 'Refuse'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `envoiDocuments()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  envoiDocuments$Response(params: {
    types: Array<'EXTRAIT_KBIS' | 'ATTESTATION_INSCRIPTION_RCS' | 'AVIS_SITUATION_SIRET_SIREN' | 'ATTESTATION_ENREGISTREMENT' | 'MANDAT_REPRESENTANT_LEGAL' | 'ATTESTATION_GESTION' | 'CERTIFICAT' | 'CONVENTION'>;
    body?: { 'pieces': Array<Blob> }
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, GestionDesDocumentsService.EnvoiDocumentsPath, 'post');
    if (params) {
      rb.query('types', params.types, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Ajout des pièces justificatives pour une entité.
   *
   * La méthode est accessible au utilisateur ayant le rôle gestionnaire de flotte ou gestionnaire de flotte admin et dont le compte est 'En attente de finalisation' ou 'Refuse'
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `envoiDocuments$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  envoiDocuments(params: {
    types: Array<'EXTRAIT_KBIS' | 'ATTESTATION_INSCRIPTION_RCS' | 'AVIS_SITUATION_SIRET_SIREN' | 'ATTESTATION_ENREGISTREMENT' | 'MANDAT_REPRESENTANT_LEGAL' | 'ATTESTATION_GESTION' | 'CERTIFICAT' | 'CONVENTION'>;
    body?: { 'pieces': Array<Blob> }
  }): Observable<boolean> {

    return this.envoiDocuments$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

}
