import { AbstractControl, Validators } from '@angular/forms';

export function deepMerge(target: Object, source: Object): Object {
  Object.entries(source).forEach(([key, value]) => {
    if (value && !(value instanceof Array) && value instanceof Object) {
      deepMerge(target[key] = target[key] || {}, value);
      return;
    }
    target[key] = value;
  });
  return target;
}

export function setOrClearRequiredValidator(control: AbstractControl , isRequired: boolean, clearValues = true) {
  isRequired ?
    control.setValidators([Validators.required]) :
    control.clearValidators();
  if (clearValues) {
    control.reset();
  }
  control.updateValueAndValidity();
}
