import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { User } from '../../../../../common/src/lib/shared/models/user.model';
import { ConventionStatus } from '../../../../../common/src/lib/shared/enums/convention-status.enum';
import { SwaPartAuthService } from '../auth/swa-part-auth.service';
import { RoutesEnum } from '../enums/routes.enum';
import { GestionFeatureFlippingService } from '../../../../../common/src/lib/shared/services/Gestion-feature-flipping.service';
import { MessagerieService } from '../../../../../common/src/lib/api/api-service-messagerie/services/messagerie.service';
import { timer } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { AntaiMessagerieService } from '../../../../../common/src/lib/shared/components/messagerie/antai-messagerie.service';


@Component({
  selector: 'swa-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  loggedTabs: { label: string, routerLink: string, condition: string, hidden: boolean }[];
  user: User;
  // TODO : FF Messagerie Feature Flipping
  ffMessagerie: boolean;

  nbNewMessages: number;

  constructor(private translateService: TranslateService,
              private gestionFeatureFlippingService: GestionFeatureFlippingService,
              private antaiMessagerieService: AntaiMessagerieService) {
  }

  _authService: SwaPartAuthService;
  routeEnum = RoutesEnum;

  ngOnInit() {
    this.antaiMessagerieService.nbConversationsNonLues.subscribe({
      next: nombre => {
        this.nbNewMessages = nombre;
      }
    });
  }

  @Input()
  set authService(value: SwaPartAuthService) {
    if (value) {
      this._authService = value;
      this.getUserConventionsAndDisplayLoggedTabs();
    }
  }

  getUserConventionsAndDisplayLoggedTabs() {
    this._authService.currentUser.subscribe((user: User) => {
      if (user) {
        this.user = user;
        this.displayLoggedTabs(user);
      } else {
        this.loggedTabs = null;
      }
    });
  }

  displayLoggedTabs(user: User) {

    this.translateService.get('header.logged_tabs')
      .subscribe((value: { [key: string]: { label: string, routerLink: string, condition: string, hidden: boolean } }) => {

          this.loggedTabs = new Array<{ label: string, routerLink: string, condition: string, hidden: boolean }>();

          // push de l'onglet mon compte
          this.loggedTabs.push(value['MON_COMPTE']);

          for (const convention of Object.keys(user.mapTypeEtatConventions)) {

            const currentTab = value[convention];

            currentTab.hidden = user.mapTypeEtatConventions[convention] === ConventionStatus.ABSENT;

            this.loggedTabs.push(currentTab);
          }

          // push de l'onglet documents
          this.loggedTabs.push(value['DOCUMENTS']);

          // push de l'onglet Ma facturation
          this.gestionFeatureFlippingService.getCleFacturation().subscribe((valueFF) => {
            this.gestionFeatureFlippingService.getCleFacturationFPS().subscribe((valueFFfps) => {
              // Cas ou la clé de FF Factu FPS a true
              if (valueFFfps) {
                if (valueFF && !this.loggedTabs.find(e => e.label === 'MA FACTURATION')) {
                  if ((user.mapTypeEtatConventions['FOURRIERE'] && user.mapTypeEtatConventions['FOURRIERE'] !== ConventionStatus.ABSENT
                      && user.mapTypeEtatConventions['FOURRIERE'] !== ConventionStatus.EN_COURS)
                    || (user.mapTypeEtatConventions['FPS'] && user.mapTypeEtatConventions['FPS'] !== ConventionStatus.ABSENT
                      && user.mapTypeEtatConventions['FPS'] !== ConventionStatus.EN_COURS)
                    || (user.mapTypeEtatConventions['FREEFLOW'] && user.mapTypeEtatConventions['FREEFLOW'] !== ConventionStatus.ABSENT
                      && user.mapTypeEtatConventions['FREEFLOW'] !== ConventionStatus.EN_COURS)) {
                    this.loggedTabs.push(value['MA_FACTURATION']);
                  }
                }
              //  sinon cas ou FF Factu FPS a false -> ce bloc sera a supprimer lors des suppression des clé de FF
              } else {
                if (valueFF && !this.loggedTabs.find(e => e.label === 'MA FACTURATION')) {
                  if ((user.mapTypeEtatConventions['FOURRIERE'] && user.mapTypeEtatConventions['FOURRIERE'] !== ConventionStatus.ABSENT
                      && user.mapTypeEtatConventions['FOURRIERE'] !== ConventionStatus.EN_COURS)
                    || (user.mapTypeEtatConventions['FREEFLOW'] && user.mapTypeEtatConventions['FREEFLOW'] !== ConventionStatus.ABSENT
                      && user.mapTypeEtatConventions['FREEFLOW'] !== ConventionStatus.EN_COURS)) {
                    this.loggedTabs.push(value['MA_FACTURATION']);
                  }
                }
              }
            });
          });

        // push de l'onglet messagerie
          this.gestionFeatureFlippingService.getCleMessagerie().subscribe((valueFF) => {
            if (valueFF && !this.loggedTabs.find(e => e.label === 'MA MESSAGERIE')) {
              this.loggedTabs.push(value['MA_MESSAGERIE']);
              this.ffMessagerie = true;
              setTimeout(() => {
                this.getPeriodiquementNombreConversationsNonLues();
              }, 250);
            }
          });

        }
      );
  }

  /**
   * Appel du webservice permettant d'obtenir le nombre de conversations non lues toutes les minutes
   */
  getPeriodiquementNombreConversationsNonLues() {
    timer(0, 60000).subscribe({
      next: () => this.antaiMessagerieService.rafraichirNombreConversationsNonLues()
    });
  }



  deconnexion() {
    this._authService.logout();
  }
}
