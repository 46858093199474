import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuOrBreadcrumbComponent } from './menu-or-breadcrumb.component';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';


@NgModule({
  declarations: [MenuOrBreadcrumbComponent],
  exports: [MenuOrBreadcrumbComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    RouterModule
  ]
})
export class MenuOrBreadcrumbModule { }
