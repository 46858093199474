import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'antai-token-expired',
  templateUrl: './token-expired.component.html',
  styleUrls: ['./token-expired.component.scss']
})
export class TokenExpiredComponent {

  expiredLink: boolean;

  constructor(private router: Router,
              private authService: AuthService) {
    this.expiredLink = this.router.url === '/lien-expire';
  }

  goToPasswordRecoveryPage() {
      this.router.navigate(['connexion/recuperation-de-compte']);
  }

  goToConnectionPage() {
      this.authService.logout();
  }

}
