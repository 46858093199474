import { Injectable } from '@angular/core';
import { OrganisationDto, PartenaireDto } from 'projects/common/src/public-api';

@Injectable({
  providedIn: 'root'
})
export class RegisterManagerService {

  private _registerStepOrganisationValue: OrganisationDto;
  private _registerStepContactValue: PartenaireDto;

  constructor() {
    this.initOrReinitForm();
  }

  get registerStepContactValue(): PartenaireDto {
    return this._registerStepContactValue;
  }

  set registerStepContactValue(value: PartenaireDto) {
    this._registerStepContactValue = value;
  }

  set registerStepOrganisationValue(value: OrganisationDto) {
    this._registerStepOrganisationValue = value;
  }

  get registerStepOrganisationValue(): OrganisationDto {
    return this._registerStepOrganisationValue;
  }

  initOrReinitForm() {
    this._registerStepOrganisationValue = <OrganisationDto>{};
    this._registerStepContactValue = <PartenaireDto>{};
  }
}
