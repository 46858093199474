<p>
  <mat-form-field appearance="fill">
    <mat-label>{{(placeholder | translate) + (isRequired ? '*' : '')}}</mat-label>
    <!--Obligation de mettre autocomplete="none" car le off n'est pas pris en charge par Chrome https://stackoverflow.com/questions/12374442/chrome-ignores-autocomplete-off-->
    <input type="text"
           autocomplete="none"
           #address
           matInput
           [id]="idInput"
           [formControl]="formControl"
           [minLength]="minLength"
           [maxLength]="maxLength"
           [matAutocomplete]="auto">
    <mat-autocomplete
        #auto="matAutocomplete"
        [displayWith]="onSelectValue.bind(this)"
        (closed)="onSelectValue({isEnteredOption: true, value: address.value})">
      <mat-option id="my-address-entered_option"
                  [value]="{isEnteredOption: true, value: address.value}"
                  *ngIf="(address.value.toString().length >= searchMinLength) && formControl.valid">
        {{ formOptions.length  ? myAddressEntered : noResultLabel + myAddressEntered }}
      </mat-option>
      <mat-option *ngFor="let option of formOptions" [value]="option">
        {{ propDisplayedMore && option[propDisplayedMore] ?  option.label + ' (' + option[propDisplayedMore] + ')' : option.label }}
      </mat-option>
    </mat-autocomplete>
    <mat-hint align="end">{{address.value?.length + '/' + maxLength }}</mat-hint>
    <mat-error *ngIf="formControl.hasError('required')">
      {{ 'field.error.required' | translate }}
    </mat-error>
    <mat-error *ngIf="formControl.hasError('pattern')">
      {{ patternControl.errorLabel | translate }}
    </mat-error>
  </mat-form-field>
</p>
