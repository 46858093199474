import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { SwaPartAuthService } from '../../core/auth/swa-part-auth.service';
import { emailPattern } from '../../../../../common/src/lib/shared/constants/regex-patterns';

@Component({
  selector: 'antai-sign-in-card',
  templateUrl: './sign-in-card.component.html',
  styleUrls: ['./sign-in-card.component.scss']
})
export class SignInCardComponent implements OnInit {
  loginForm: FormGroup;
  error = '';
  hide = true;
  signInBtnClickEvent = new EventEmitter<Event>();
  isRequestPending = false;



  @Input() color?: string;

  constructor(
    private formBuilder: FormBuilder,
    private authService: SwaPartAuthService) {
  }

  ngOnInit() {
    this.signInBtnClickEvent.subscribe(() => {
      this.onSubmit();
    });

    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.pattern(emailPattern)]],
      password: ['', Validators.required]
    });
  }

  // getter pour faciliter l'accès aux champs du formulaire
  get f(): { [p: string]: AbstractControl } {
    return this.loginForm.controls;
  }

  onSubmit() {
    this.loginForm.markAllAsTouched();
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }
    if (!this.isRequestPending) {
      this.isRequestPending = true;
      // Recupération du token CSRF avant le login pour ne pas bloquer l'authentification
      this.authService.initApplication();
      this.authService.login(this.f.username.value, this.f.password.value)
        .pipe(
          finalize(() => {
            // réactiver le bouton
            this.isRequestPending = false;
          }),
        )
        .subscribe({
          error: err => {
            this.error = err.error.label;
          }
        });
    }
  }
}
