import { Component, Input } from '@angular/core';

@Component({
  selector: 'antai-menu-or-breadcrumb',
  templateUrl: './menu-or-breadcrumb.component.html',
  styleUrls: ['./menu-or-breadcrumb.component.scss']
})
export class MenuOrBreadcrumbComponent {

  @Input() currentStepId: number;
  @Input() tabOrSteps: Array<string>
    | Array<{ label: string, routerLink: string | string [], queryParams?: object, condition?: string, hidden?: boolean, replaceUrl: boolean}>;
  @Input() isBreadcrumb: boolean;
}
