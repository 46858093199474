/* tslint:disable */
/* eslint-disable */

/**
 * Choix du type du document que nous souhaitons déposer
 */
export enum TypeDocumentAdmin {
  ModeleConvention = 'MODELE_CONVENTION',
  ModeleConventionPmEpc1 = 'MODELE_CONVENTION_PM_EPC1',
  ModeleConventionPluricommunalite = 'MODELE_CONVENTION_PLURICOMMUNALITE',
  ModeleConventionTransportPublic = 'MODELE_CONVENTION_TRANSPORT_PUBLIC',
  ModeleConventionEtablissementPublic = 'MODELE_CONVENTION_ETABLISSEMENT_PUBLIC',
  ModeleConventionEntitePrivee = 'MODELE_CONVENTION_ENTITE_PRIVEE',
  ModeleConventionOfb = 'MODELE_CONVENTION_OFB',
  GuideConventionne = 'GUIDE_CONVENTIONNE',
  GuideNonConventionne = 'GUIDE_NON_CONVENTIONNE',
  ModeleCsv = 'MODELE_CSV',
  Cgu = 'CGU',
  LettreAttestationMandatement = 'LETTRE_ATTESTATION_MANDATEMENT',
  LettreMandatement = 'LETTRE_MANDATEMENT',
  AcoAnonyme = 'ACO_ANONYME',
  KitPve = 'KIT_PVE',
  AvisInformation = 'AVIS_INFORMATION',
  ReleveInfraction = 'RELEVE_INFRACTION',
  Article13052011 = 'ARTICLE_13_05_2011',
  Article21062011 = 'ARTICLE_21_06_2011',
  ModelePagePersonnalisation = 'MODELE_PAGE_PERSONNALISATION',
  GrilleTarifaireImpaire = 'GRILLE_TARIFAIRE_IMPAIRE',
  GrilleTarifairePaire = 'GRILLE_TARIFAIRE_PAIRE',
  ModeleConventionFpsComplet = 'MODELE_CONVENTION_FPS_COMPLET',
  ModeleConventionFpsPartiel = 'MODELE_CONVENTION_FPS_PARTIEL'
}
