<!-- Titre de la modale -->
<h2 mat-dialog-title *ngIf="data.titleLabel">{{data.titleLabel | translate}}</h2>
<!-- Message d'info -->
<div mat-dialog-content *ngIf="data.contentLabel">
  <span [innerHTML]="data.contentLabel | translate "></span>
</div>
<br/>
<antai-card class="recap-card" [value]="card"></antai-card>
<!-- Bouton de confirmation -->
<div mat-dialog-actions>
  <button *ngIf="data.leftBtnLabel" mat-flat-button class="btn-white antai-submit-btn right-btn" [mat-dialog-close] >
    {{data.leftBtnLabel | translate}}
  </button>
  <button *ngIf="data.rightBtnLabel" mat-flat-button class="btn-blue antai-submit-btn right-btn" [mat-dialog-close]="true">
    {{data.rightBtnLabel | translate}}
  </button>
</div>
