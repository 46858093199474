import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ChildrenTabsMenuComponent } from './children-tabs-menu.component';
import { MenuOrBreadcrumbModule } from '../menu-or-breadcrumb/menu-or-breadcrumb.module';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [ChildrenTabsMenuComponent],
  exports: [
    ChildrenTabsMenuComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    TranslateModule,
    RouterModule,
    MenuOrBreadcrumbModule
  ]
})
export class ChildrenTabsMenuModule { }
