<antai-card [value]="{
        title: 'sign_page.sign_in.title',
        color: color,
        isInline: true,
        headerAlign: 'center',
        footerWithBtn: {centerBtn: {
          color: 'blue',
          label: 'sign_page.sign_in.submit',
          clickEvent: {event: signInBtnClickEvent},
          disabled: loginForm.invalid || isRequestPending
        }},
        sectionsSizes: {header: 15, body: 0, footer: 15}
        }">
  <p class="field-hint">* {{ 'field.mandatory' | translate }}</p>
  <form  class="with-input_form" [formGroup]="loginForm" (keydown.enter)="onSubmit()">
    <mat-form-field appearance="fill">
      <mat-label>{{ 'sign_page.sign_in.login' | translate }}</mat-label>
      <input matInput type="text" formControlName="username" maxlength="254" required>
      <mat-error *ngIf="f.username.hasError('required')">
        {{ 'field.error.required' | translate }}
      </mat-error>
      <mat-error *ngIf="f.username.hasError('pattern') && !f.username.hasError('required')">
        {{ 'field.error.mail' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>{{ 'sign_page.sign_in.pwd' | translate }}</mat-label>
      <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" autocomplete="on" required>
      <mat-icon matSuffix (click)="hide = !hide" [className]="'cursor-pointer'">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
      <mat-error *ngIf="f.password.errors && f.password.errors.required">
        {{ 'field.error.required' | translate }}
      </mat-error>
    </mat-form-field>
    <div fxLayout.gt-sm="row">
      <mat-error [innerHTML]="error | translate">
      </mat-error>
    </div>
  </form>
  <div fxFlex fxLayoutAlign="end">
    <a class="clickable-blue"
       href="#"
       [routerLink]="['/connexion/recuperation-de-compte']">
      {{ 'sign_page.sign_in.pwd_lost' | translate }}
    </a>
  </div>
</antai-card>

