<div id="expired-link_page">

  <!--  LIEN EXPIRE (lien envoyé par mail)-->
  <ng-container *ngIf="expiredLink">
    <h1>{{ 'expired_link.title'  | translate }}</h1>
    <p>{{ 'expired_link.message' | translate }}</p>
    <button mat-flat-button class="btn-blue antai-submit-btn" (click)="goToPasswordRecoveryPage()">
      {{ 'expired_link.button' | translate }}
    </button>
  </ng-container>

</div>
