import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../../shared/models/user.model';
import { UtilisateurConnecte } from '../../api/api-gateway-sgf/models/utilisateur-connecte';
import { map } from 'rxjs/operators';
import { SgfAuthentificationService } from '../../api/api-gateway-sgf/services/sgf-authentification.service';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { StrictHttpResponse } from '../../api/api-gateway-sgf/strict-http-response';

@Injectable({
  providedIn: 'root',
})
export abstract class AuthService {
  cleSessionExpiree = 'sessionExpiree';
  loginCredential: string;

  protected currentUserSubject: BehaviorSubject<User>;
  currentUser: Observable<User>;
  // Enregistrement de l'url demandé pour rediriger après récupération du token
  redirectUrl: string;

  protected constructor(protected sgfAuthentificationService: SgfAuthentificationService,
                        protected router: Router) {

    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();

  }

  public get currentUserValue(): User {
    return JSON.parse(localStorage.getItem('currentUser'));
  }

  setCurrentUserValue(user: User) {
    if (this.currentUserSubject.value) {
      user = Object.assign(
        this.currentUserSubject.value,
        user
      );
    }
    this.currentUserSubject.next(user);
    localStorage.setItem('currentUser', JSON.stringify(user));
  }
  login(login: string, accesCacher: string): Observable<StrictHttpResponse<UtilisateurConnecte>> {
    this.loginCredential =  btoa(login + ':' + accesCacher);
    return this.sgfAuthentificationService.authenticateUser$Response()
      .pipe(map((resp: StrictHttpResponse<UtilisateurConnecte>) => {
        this.redirectAccordingStatus();
        return resp;
      }));
  }
  refreshLogin(): Observable<StrictHttpResponse<UtilisateurConnecte>> {
    return this.sgfAuthentificationService.authenticateUser$Response()
      .pipe(map((resp: StrictHttpResponse<UtilisateurConnecte>) => {
        this.initApplication();
        return resp;
      }));
  }
  refreshToken(): Observable<StrictHttpResponse<UtilisateurConnecte>> {
    return this.sgfAuthentificationService.refreshToken$Response({
      rafraichissementToken: this.currentUserValue.rafraichissementToken
    });
  }

  logout(sessionExpiree?: boolean) {

    localStorage.clear();

    if (sessionExpiree) {
     this.setSessionExpiree(true);
    }

    this.router.navigate(['/connexion']);
  }

  initApplication() {
    // Récupération du cookie XSRF
    this.sgfAuthentificationService.initApplication$Response()
      .subscribe({
        error: () => {}
      });
  }

  private setSessionExpiree(sessionExpiree: boolean) {
    localStorage.setItem(this.cleSessionExpiree, String(sessionExpiree));
  }

  public getSessionExpiree(): boolean {
   return JSON.parse(localStorage.getItem(this.cleSessionExpiree)) ;
  }

  public removeSessionExpiree() {
    localStorage.removeItem(this.cleSessionExpiree);
  }

  public gestionSessionExpiree(): boolean {
   const afficherSessionExpiree = this.getSessionExpiree();

    this.removeSessionExpiree();

    return afficherSessionExpiree;
  }

  abstract redirectAccordingStatus(): void;
  abstract redirectAccordingAccountType(url: string): boolean;
}
