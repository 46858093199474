import {Component, Input, OnChanges} from '@angular/core';
import {GestionFeatureFlippingService} from '../../services/Gestion-feature-flipping.service';

@Component({
  selector: 'app-feature-flipping',
  template: '<ng-container *ngIf="estActif"><ng-content></ng-content></ng-container>'
})
export class FeatureFlippingComponent implements OnChanges {

  @Input() cle = '';
  estActif = false;

  constructor(private readonly featureFlippingService: GestionFeatureFlippingService) {
  }

  /**
   * ngOnChanges, cf méthode native d'Angular.
   * Vérifie que la fonctionnalité désignée par la clé en entrée est active ou non
   **/
  ngOnChanges() {
    this.featureFlippingService.checkFF(this.cle).subscribe(valeur => this.estActif = valeur);
  }
}
