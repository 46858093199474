<div id="menu-or-breadcrumb_container" fxLayout="layout">
  <ng-container *ngFor="let tabOrstep of tabOrSteps; let i=index; let isLast = last">
    <nav fxFlex>
        <a class="tab-or-step"
           fxLayout="row"
           *ngFor="let line of isBreadcrumb ? [1,2] : [1]"
           [ngClass]="!tabOrstep.hidden ? isBreadcrumb ? i !== currentStepId - 1 ? i < currentStepId ? 'progress-steps' : 'remaining-steps border' : 'current-step'
                    : 'menu ' + (rla.isActive ? 'progress-steps' : 'remaining-steps cursor-pointer') : ''"
           [queryParams]="tabOrstep.queryParams"
           [replaceUrl]="tabOrstep.replaceUrl"
           [routerLink]="!tabOrstep.hidden ? tabOrstep.routerLink : []"
           [ngStyle]="rla.isActive ? {'pointer-events': 'none'}: null"
           [attr.tabIndex]="!tabOrstep.hidden ? 0 : -1"
           #rla="routerLinkActive"
           routerLinkActive>
        <div fxFlex="4"></div>
        <span *ngIf="!tabOrstep.hidden" fxFlex class="breadcrumb-step_label">
          <div *ngIf="isBreadcrumb && line === 1" id="step-number_container">
            <span>{{i+1}}</span>
          </div>
          <p>
            {{ isBreadcrumb && line === 1 ? '' : tabOrstep.label ? tabOrstep.label : tabOrstep }}
          </p>
        </span>
        <div fxFlex="4"></div>
        <div fxFlex="4" class="vertical-center_flex">
          <span *ngIf="isBreadcrumb && line === 1" class="material-icons arrow-icon">{{!isLast ? 'arrow_forward_ios' : ''}}</span>
        </div>
      </a>
    </nav>
  </ng-container>
</div>
