import { Component } from '@angular/core';
import { TranslationService } from '../../../common/src/lib/core/services/translation.service';
// @ts-ignore
import * as translation_swa_part from '../assets/i18n/fr.toml';
import { SwaPartAuthService } from './core/auth/swa-part-auth.service';
import { GestionFeatureFlippingService } from '../../../common/src/lib/shared/services/Gestion-feature-flipping.service';

@Component({
  selector: 'antai-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  constructor(private translationService: TranslationService,
              public swaPartAuthService: SwaPartAuthService) {
    translationService.InitLocalLanguage(translation_swa_part);
  }
}
