import { Component } from '@angular/core';
import { AppVersion } from '../../app.version';


@Component({
  selector: 'antai-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

export class FooterComponent {
  readonly appVersion = AppVersion;
}
