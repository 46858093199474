import { FooterWithBtn } from '../footer-with-btn/footer-with-btn.model';
import { EventEmitter } from '@angular/core';

export class AntaiCard {
  title?: string;
  color?: string;
  isInline?: boolean;
  footerWithBtn?: FooterWithBtn;
  subTitle?: string;
  sectionsSizes?: {header: number, body?: number, footer?: number};
  blockLists?: {layoutType: string, lists: { rowType: string, title: string, values: string[]}[]};
  headerAlign?: string;
  editLink?: {routePath?: string[], clickEvent?: EventEmitter<Event>};
  viewIconBtn?: {routePath?: string[], clickEvent?: {event: EventEmitter<any>, value?: any}};

  /**
   * @param title Titre contenu dans l'en-tête
   * @param color Couleur de fond (bleu <shaded blue>, blanc <shaded white>, gris <shaded grey>)
   * @param viewIconBtn Permet d'afficher l'oeil en haut a droite de la card
   * @param isInline Permet à 2 cards alignée d'avoir la même hauteur
   * @param footerWithBtn Intégration du composant 'footerWithBtn' dans le pied
   * @param subTitle Sous-titre contenu dans l'en-tête
   * @param sectionsSizes Permet de configurer la hauteur de l'en-tête, du corps et du pied en pourcentage
   * @param blockLists Intégration de listes configurables sur 2 colonnes
   * @param headerAlign Alignement du contenue de l'en-tête (center, left, right)
   * @param editLink Url et/ou event configuré pour le lien "Modifier" (Non affiché si aucune valeur)
   */
  constructor(
    title?: string,
    color?: string,
    isInline?: boolean,
    footerWithBtn?: FooterWithBtn,
    subTitle?: string,
    sectionsSizes?: {header: number, body?: number, footer?: number},
    blockLists?: {layoutType: string, lists: { rowType: string, title: string, values: string[]}[]},
    headerAlign?: string,
    editLink?: {routePath?: string[], clickEvent?: EventEmitter<Event>},
    viewIconBtn?: {routePath?: string[], clickEvent?: {event: EventEmitter<any>, value?: any}},
  ) {
    this.title = title;
    this.color = color;
    this.isInline = isInline;
    this.footerWithBtn = footerWithBtn;
    this.subTitle = subTitle;
    this.sectionsSizes = sectionsSizes;
    this.blockLists = blockLists;
    this.headerAlign = headerAlign ? headerAlign : 'left';
    this.editLink = editLink;
    this.viewIconBtn = viewIconBtn;
  }
}
