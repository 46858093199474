<div id="header-container" fxLayout="row">
  <div fxFlex="7.5"></div>
  <div id="header-content-container" fxFlex="85">
    <div class="flex">
      <div class="flex">
        <div class="antail-logo-container">
          <img class="gouv-img" src="assets/images/marianne-logo.png"
               alt="{{ 'header.logo_marianne_alt' | translate }}">
        </div>
        <div class="antail-logo-container">
          <a
            title="Retour à l'accueil"
            class="antail-logo-container"
            href="/">
            <img class="gouv-img" src="assets/images/antai-logo.png" alt="{{ 'header.logo_antai_alt' | translate }}">
          </a>
        </div>
      </div>
      <div fxFlex="80"></div>
      <span class="example-spacer"></span>
      <a class="envelop" [routerLink]="['../' + routeEnum.PARTNER_SPACE + '/' + routeEnum.MA_MESSAGERIE]" *ngIf="_authService?.currentUserValue && ffMessagerie">
        <div fxLayout.gt-xs="column" fxFlex class="m-auto mb-50 clickable-blue message">
          <span class="material-icons">mail</span>
          <div *ngIf="nbNewMessages" class="cercle-message-non-lu"></div>
          <span class="textMessage" *ngIf="nbNewMessages === 1">{{'messagerie.unread_message_notification' | translate }}</span>
          <span class="textMessage" *ngIf="nbNewMessages > 1">{{'messagerie.unread_messages_notification' | translate : {'nbMessages': nbNewMessages} }}</span>
        </div>
      </a>
      <div class="vertical-center_flex" *ngIf="_authService?.currentUserValue">
        <button class="btn-white antai-submit-btn" mat-flat-button (click)="deconnexion()">
          {{ 'header.logout' | translate }}
        </button>
      </div>
    </div>
    <antai-menu-or-breadcrumb *ngIf="loggedTabs && _authService.currentUserValue"
                              [tabOrSteps]="loggedTabs"></antai-menu-or-breadcrumb>
  </div>
  <div fxFlex="7.5"></div>
</div>
