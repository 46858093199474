/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { UtilisateurConnecte } from '../models/utilisateur-connecte';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';


/**
 * API d'authentification SGF
 */
@Injectable({
  providedIn: 'root',
})
export class SgfAuthentificationService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation refreshToken
   */
  static readonly RefreshTokenPath = '/rafraichirtoken';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `refreshToken()` instead.
   *
   * This method doesn't expect any request body.
   */
  refreshToken$Response(params: {
    rafraichissementToken: string;
  }): Observable<StrictHttpResponse<UtilisateurConnecte>> {

    const rb = new RequestBuilder(this.rootUrl, SgfAuthentificationService.RefreshTokenPath, 'post');
    if (params) {
      rb.query('rafraichissementToken', params.rafraichissementToken, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UtilisateurConnecte>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `refreshToken$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  refreshToken(params: {
    rafraichissementToken: string;
  }): Observable<UtilisateurConnecte> {

    return this.refreshToken$Response(params).pipe(
      map((r: StrictHttpResponse<UtilisateurConnecte>) => r.body as UtilisateurConnecte)
    );
  }

  /**
   * Path part for operation authenticateUser
   */
  static readonly AuthenticateUserPath = '/login';

  /**
   * Authentifier un utilisateur.
   *
   * Les identifiants de l'utilisateur sont passés via le protocole d'authentification Basic dans le headder Authorization.<br /> La valeur header doit être de la forme suivante : Basic <b>token</b>.<br /> Où <b>token</b> est la chaîne de caractères suivante encodée en base64 : <b>login</b>:<b>motdepasse</b>
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticateUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  authenticateUser$Response(params?: {
  }): Observable<StrictHttpResponse<UtilisateurConnecte>> {

    const rb = new RequestBuilder(this.rootUrl, SgfAuthentificationService.AuthenticateUserPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UtilisateurConnecte>;
      })
    );
  }

  /**
   * Authentifier un utilisateur.
   *
   * Les identifiants de l'utilisateur sont passés via le protocole d'authentification Basic dans le headder Authorization.<br /> La valeur header doit être de la forme suivante : Basic <b>token</b>.<br /> Où <b>token</b> est la chaîne de caractères suivante encodée en base64 : <b>login</b>:<b>motdepasse</b>
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `authenticateUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  authenticateUser(params?: {
  }): Observable<UtilisateurConnecte> {

    return this.authenticateUser$Response(params).pipe(
      map((r: StrictHttpResponse<UtilisateurConnecte>) => r.body as UtilisateurConnecte)
    );
  }

  /**
   * Path part for operation initApplication
   */
  static readonly InitApplicationPath = '/init';

  /**
   * Initialisation de l'application.
   *
   * Permet de récupérer le cookie CSRF
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `initApplication()` instead.
   *
   * This method doesn't expect any request body.
   */
  initApplication$Response(params?: {
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, SgfAuthentificationService.InitApplicationPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Initialisation de l'application.
   *
   * Permet de récupérer le cookie CSRF
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `initApplication$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  initApplication(params?: {
  }): Observable<string> {

    return this.initApplication$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

}
