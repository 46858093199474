import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from 'projects/common/src/lib/core/components/not-found/not-found.component';
import { SignComponent } from './sign/sign.component';
import { RoutesEnum } from './core/enums/routes.enum';
import { SwaPartAuthGuard } from './core/guards/swa-part-auth.guard';

const routes: Routes = [
  { path: '', redirectTo: RoutesEnum.PARTNER_SPACE, pathMatch: 'full'},
  {
    path: RoutesEnum.PARTNER_SPACE,
    canActivateChild: [SwaPartAuthGuard],
    children: [
      {path: '', redirectTo: RoutesEnum.ACCOUNT, pathMatch: 'full'},
      {
        path: RoutesEnum.ACCOUNT,
        loadChildren: () => import('./partner-space/partner-space-account/partner-space-account-routing.module')
          .then(m => m.PartnerSpaceAccountRoutingModule)
      },
      {
        path: RoutesEnum.DOCUMENTS,
        loadChildren: () => import('./partner-space/partner-space-mes-documents/partner-space-mes-documents-routing.module')
          .then(m => m.PartnerSpaceMesDocumentsComponentRoutingModule)
      },
      {
        path: RoutesEnum.DOCUMENTATION_PVE,
        loadChildren: () => import('./partner-space/partner-space-documentation-pve/partner-space-documentation-routing.module')
          .then(m => m.PartnerSpaceDocumentationRoutingModule)
      },
      {
        path: RoutesEnum.MA_MESSAGERIE,
        loadChildren: () => import('./partner-space/partner-space-messagerie/partner-space-messagerie-routing.module')
          .then(m => m.PartnerSpaceMessagerieRoutingModule)
      },
      {
        path: RoutesEnum.MA_FACTURATION,
        loadChildren: () => import('./partner-space/partner-space-ma-facturation/ma-facturation-routing.module')
          .then(m => m.MaFacturationRoutingModule)
      },
      {
        path: ':conventionServiceType',
        loadChildren: () => import('./partner-space/partner-space-convention/partner-space-convention-routing.module')
          .then(m => m.PartnerSpaceConventionRoutingModule)
      },
    ]
  },

  { path: RoutesEnum.SIGN_IN, component: SignComponent },
  {
    path: RoutesEnum.REGISTER,
    loadChildren: () => import('./sign/register/register-routing.module').then(m => m.RegisterRoutingModule)
  },
  {
    path: 'mon-compte/mot-de-passe',
    loadChildren: () => import('projects/common/src/lib/shared/components/account-password/account-password.module')
      .then(m => m.AccountPasswordModule)
  },
  {
    path: 'connexion/recuperation-de-compte',
    loadChildren: () => import('projects/common/src/lib/shared/components/account-password/account-password.module')
      .then(m => m.AccountPasswordModule)
  },
  {path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
