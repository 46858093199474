export enum AdminRoutesName {
  ESPACE_ADMINISTRATEUR = 'espace-administrateur',
  EDITION = 'edition',
  CREATION = 'creation',
  COMPTE = 'compte',
  CONNEXION = 'connexion',
  RECUPERATION_DE_COMPTE = 'recuperation-de-compte',

  // SGF
  SGF = 'sgf',

  SOCIETE = 'societe',
  SOCIETES = 'societes',
  GESTION_COMPTE = 'gestion-comptes',
  CONSULTATION_LOT = 'consultationLots',
  DETAIL_LOT = 'detailLots',
  HISTORIQUE_LOTS = 'historique-lots',
  DOCUMENTS = 'documents',
  UTILISATEURS_SGF = 'utilisateurs-sgf',
  CREATION_UTILISATEUR_SGF = 'creation-utilisateur-sgf',
  CREATION_DOCUMENT = 'creation-documents',
  EDITION_UTILISATEUR_SGF = 'edition-utilisateur-sgf',
  CONVENTION = 'convention',

  // Documents
  MES_DOCUMENTS = 'mes-documents',

  // Gestion comptes
  ANTAI = 'antai',
  ANTAI_EDITION = 'edition/compte',

  // Partenaires
  CONVENTION_PVE = 'conventionPVE',
  CONVENTION_FPS = 'conventionFPS',
  CONVENTION_FOURIERE = 'conventionFourriere',
  CONVENTION_AUTOROUTE = 'conventionAutoroute',
  COMPTES_PARTENAIRES = 'comptes-partenaires',
  UTILISATEURS = 'utilisateurs',
  ORGANISATIONS = 'organisations',
  CONVENTIONS = 'conventions',
  PARAMETRAGE_SERVICES = 'parametrageServices',
  MESSAGERIE = 'messagerie',

  // Messagerie
  NEW_MESSAGE = 'nouveau-message',
  MESSAGES = 'messages',

  // Facturation
  FACTURATION = 'facturation',
  INFORMATIONS_FACTURATION = 'infos',
  DONNEES_FACTURATION = 'donnees',
  FORMULAIRE_FACTURATION = 'formulaire',
  HISTORIQUE_FACTURATION = 'historique',
  FACTURATION_FREEFLOW = 'factuFreeflow',
  HISTORIQUE_FREEFLOW = 'histoFreeflow',

  // VEHICULES SPECIAUX
  VSP = 'vehicules-speciaux',
  VSP_ORGANISATIONS = 'organisations',
  VSP_UTILISATEURS = 'utilisateurs'

}
