import { ServiceDeGestionDesDocumentsService } from '../../api/api-gestion-documents-sgf/services/service-de-gestion-des-documents.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { GestionDesDocumentsService } from '../../api/api-gestion-acces-sgf/services/gestion-des-documents.service';
import { AuthService } from '../../core/auth/auth.service';
import { TypeDocumentAdmin } from '../../api/api-gestion-documents-sgf/models/type-document-admin';
import { StrictHttpResponse } from '../../api/api-gestion-documents-sgf/strict-http-response';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

const REGEX_FILE_NAME: RegExp = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;

@Injectable({
  providedIn: 'root',
})

export class DownloadFileService {

  constructor(private gestionDesDocumentsService: GestionDesDocumentsService,
              private documentsService: ServiceDeGestionDesDocumentsService,
              private authService: AuthService,
              private snackBar: MatSnackBar,
              private translateService: TranslateService) {
  }

  downloadByLink(linkElement: HTMLLinkElement, fileType: TypeDocumentAdmin, origine: 'SGF' | 'SWAPART' | 'ADMIN', serviceAssocie?: 'FREEFLOW' | 'FOURRIERE' | 'PVE') {
    linkElement.style.textDecoration = 'underline';
    ['click', 'keypress'].forEach( evt =>
      linkElement.addEventListener(evt, (e: KeyboardEvent) => {
        if (e.key === 'Enter' || evt === 'click') {
          this.getOrDownloadDocument(fileType, serviceAssocie, origine);
        }
      })
    );
  }

  downloadPj(id: number) {
    this.getFileOrDownload(this.gestionDesDocumentsService.getDocument$Response({id}));
  }

  getOrDownloadDocument(
    publicDocType: TypeDocumentAdmin,
    serviceAssocie: 'FREEFLOW' | 'FOURRIERE' | 'PVE' | 'FPS',
    origine?: 'SGF' | 'SWAPART' | 'ADMIN',
    getBlobOnlyCallback?: any) {
    if (this.authService.currentUserValue) {
      this.getFileOrDownload(this.documentsService.getFichier$Response({
        type: publicDocType,
        service: serviceAssocie
      }), getBlobOnlyCallback);
    } else {
      this.getFileOrDownload(this.documentsService.getFichierPublique$Response({type: publicDocType, origine}), getBlobOnlyCallback);
    }
  }

  private getFileOrDownload(serviceCallMethod: Observable<StrictHttpResponse<Blob>>, getBlobOnlyCallback?: any) {
    serviceCallMethod.subscribe(
      (resp: StrictHttpResponse<Blob>) => {
        if (getBlobOnlyCallback) {
          getBlobOnlyCallback(resp.body);
        } else {
          this.download(resp.body, resp.headers.get('Content-Disposition'));
        }
      },
      (error) => {

        if (error.status === 404) {

          const messageErreur = this.translateService.instant('document_indisponible');

          this.snackBar.open(messageErreur, null, {
            duration: 10000,
            panelClass: ['snackbar-error']
          });
        }

      }
    );
  }

  download(file: Blob, contentDisposition?: string, fileName?: string) {
    const currentFileName = (fileName || REGEX_FILE_NAME.exec(contentDisposition)?.[1]) ?? null;
    const a = document.createElement('a');
    if (typeof file === 'string') {
      file = new Blob([file], {type: 'text/csv'});
    }
    const blob = new Blob([file]);
    const objectUrl = URL.createObjectURL(blob);
    a.href = objectUrl;
    a.download = currentFileName;
    a.click();
    URL.revokeObjectURL(objectUrl);
  }

  createImageUrlFromBlob(blob: Blob, callback: any) {
    const reader = new FileReader();
    reader.addEventListener('load', callback, false);
    if (blob) {
      reader.readAsDataURL(blob);
    }
  }

  getCertificatContent(file: File, callback: any) {
    const reader = new FileReader();
    reader.addEventListener('load', callback, false);
    if (file) {
      reader.readAsText(file);
    }
  }

  getBase64(file): Promise<string | ArrayBuffer> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.toString().split(',')[1]);
      reader.onerror = error => reject(error);
    });
  }
}
