import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'children-tabs-menu',
  templateUrl: './children-tabs-menu.component.html',
  styleUrls: ['./children-tabs-menu.component.scss']
})
export class ChildrenTabsMenuComponent {
  @Input() pageTitleKeyLabel: string;
  @Input() tabs: {label: string, routerLink: string, condition?: string}[];
  @Input() backLink: {labelKey: string, routerLink?: string, tableLink?: string};

  constructor(public router: Router) {}

  backToTable() {
    this.router.navigateByUrl(this.backLink.tableLink);
  }
}
