import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DownloadFileService } from '../../../services/download-file.service';

@Component({
  selector: 'antai-upload-file-modal',
  templateUrl: './antai-upload-file-modal.component.html',
  styleUrls: ['./antai-upload-file-modal.component.scss']
})
export class AntaiUploadFileModalComponent {

  pjHtmlSrc: string | ArrayBuffer;

  constructor(@Inject(MAT_DIALOG_DATA) public file: File,
              private downloadFileService: DownloadFileService) {

    if (file.type === 'application/pdf'
        || file.type === 'image/jpeg'
        || file.type === 'application/zip'
        || file.type === 'image/tiff'
        || file.type === 'application/x-zip-compressed') {
      downloadFileService.createImageUrlFromBlob(file, (resp: any) => {
        this.pjHtmlSrc = resp.currentTarget.result;
      });
    } else if (file.type === 'application/x-x509-ca-cert') {
      downloadFileService.getCertificatContent(file, (resp: any) => {
        this.pjHtmlSrc = resp.currentTarget.result;
      });
    }

  }

  handleClickDownload(base64) {
    const src = base64;
    const link = document.createElement('a');
    link.href = src;
    link.download = this.file.name ?? 'document';
    link.click();
  }
}
