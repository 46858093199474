/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DocumentPubliqueDto } from '../models/document-publique-dto';
import { TypeDocumentAdmin } from '../models/type-document-admin';

@Injectable({
  providedIn: 'root',
})
export class ServiceDeGestionDesDocumentsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getListDocuments
   */
  static readonly GetListDocumentsPath = '/documents';

  /**
   * Récupération des documents administratifs .
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getListDocuments()` instead.
   *
   * This method doesn't expect any request body.
   */
  getListDocuments$Response(params?: {
  }): Observable<StrictHttpResponse<Array<DocumentPubliqueDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ServiceDeGestionDesDocumentsService.GetListDocumentsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DocumentPubliqueDto>>;
      })
    );
  }

  /**
   * Récupération des documents administratifs .
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getListDocuments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getListDocuments(params?: {
  }): Observable<Array<DocumentPubliqueDto>> {

    return this.getListDocuments$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DocumentPubliqueDto>>) => r.body as Array<DocumentPubliqueDto>)
    );
  }

  /**
   * Path part for operation updateDocument
   */
  static readonly UpdateDocumentPath = '/documents';

  /**
   * Téléchargement d'un document .
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateDocument()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  updateDocument$Response(params: {
    types: TypeDocumentAdmin;
    titre: string;
    origine: 'SGF' | 'SWAPART' | 'ADMIN';
    accessibilite?: boolean;
    service?: 'FOURRIERE' | 'FREEFLOW' | 'PVE' | 'FPS';
    body?: { 'pieces'?: Blob }
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ServiceDeGestionDesDocumentsService.UpdateDocumentPath, 'put');
    if (params) {
      rb.query('types', params.types, {});
      rb.query('titre', params.titre, {});
      rb.query('origine', params.origine, {});
      rb.query('accessibilite', params.accessibilite, {});
      rb.query('service', params.service, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Téléchargement d'un document .
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateDocument$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  updateDocument(params: {
    types: TypeDocumentAdmin;
    titre: string;
    origine: 'SGF' | 'SWAPART' | 'ADMIN';
    accessibilite?: boolean;
    service?: 'FOURRIERE' | 'FREEFLOW' | 'PVE' | 'FPS';
    body?: { 'pieces'?: Blob }
  }): Observable<boolean> {

    return this.updateDocument$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation getFichierPublique
   */
  static readonly GetFichierPubliquePath = '/documents/publique/{origine}/{type}/fichier';

  /**
   * Téléchargement d'un document .
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFichierPublique()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFichierPublique$Response(params: {
    origine: 'SGF' | 'SWAPART' | 'ADMIN';
    type: TypeDocumentAdmin;
  }): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, ServiceDeGestionDesDocumentsService.GetFichierPubliquePath, 'get');
    if (params) {
      rb.path('origine', params.origine, {});
      rb.path('type', params.type, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'multipart/form-data'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Téléchargement d'un document .
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFichierPublique$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFichierPublique(params: {
    origine: 'SGF' | 'SWAPART' | 'ADMIN';
    type: TypeDocumentAdmin;
  }): Observable<Blob> {

    return this.getFichierPublique$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation getFichier
   */
  static readonly GetFichierPath = '/documents/{type}/fichier';

  /**
   * Téléchargement d'un document pour SGF ou SWAPART.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFichier()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFichier$Response(params: {
    type: TypeDocumentAdmin;
    service?: 'FOURRIERE' | 'FREEFLOW' | 'PVE' | 'FPS';
  }): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, ServiceDeGestionDesDocumentsService.GetFichierPath, 'get');
    if (params) {
      rb.path('type', params.type, {});
      rb.query('service', params.service, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'multipart/form-data'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Téléchargement d'un document pour SGF ou SWAPART.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFichier$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFichier(params: {
    type: TypeDocumentAdmin;
    service?: 'FOURRIERE' | 'FREEFLOW' | 'PVE' | 'FPS';
  }): Observable<Blob> {

    return this.getFichier$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation getDocument
   */
  static readonly GetDocumentPath = '/documents/{type}';

  /**
   * Récupération des informations d'un document pour SGF ou SWAPART.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocument$Response(params: {
    type: TypeDocumentAdmin;
    service?: 'FOURRIERE' | 'FREEFLOW' | 'PVE' | 'FPS';
  }): Observable<StrictHttpResponse<DocumentPubliqueDto>> {

    const rb = new RequestBuilder(this.rootUrl, ServiceDeGestionDesDocumentsService.GetDocumentPath, 'get');
    if (params) {
      rb.path('type', params.type, {});
      rb.query('service', params.service, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DocumentPubliqueDto>;
      })
    );
  }

  /**
   * Récupération des informations d'un document pour SGF ou SWAPART.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocument(params: {
    type: TypeDocumentAdmin;
    service?: 'FOURRIERE' | 'FREEFLOW' | 'PVE' | 'FPS';
  }): Observable<DocumentPubliqueDto> {

    return this.getDocument$Response(params).pipe(
      map((r: StrictHttpResponse<DocumentPubliqueDto>) => r.body as DocumentPubliqueDto)
    );
  }

  /**
   * Path part for operation getFichierAdmin
   */
  static readonly GetFichierAdminPath = '/documents/{origine}/{type}/fichier';

  /**
   * Téléchargement d'un document .
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFichierAdmin()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFichierAdmin$Response(params: {
    origine: 'SGF' | 'SWAPART' | 'ADMIN';
    type: TypeDocumentAdmin;
    service?: 'FOURRIERE' | 'FREEFLOW' | 'PVE' | 'FPS';
  }): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, ServiceDeGestionDesDocumentsService.GetFichierAdminPath, 'get');
    if (params) {
      rb.path('origine', params.origine, {});
      rb.path('type', params.type, {});
      rb.query('service', params.service, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'multipart/form-data'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Téléchargement d'un document .
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFichierAdmin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFichierAdmin(params: {
    origine: 'SGF' | 'SWAPART' | 'ADMIN';
    type: TypeDocumentAdmin;
    service?: 'FOURRIERE' | 'FREEFLOW' | 'PVE' | 'FPS';
  }): Observable<Blob> {

    return this.getFichierAdmin$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation getDocumentAdmin
   */
  static readonly GetDocumentAdminPath = '/documents/{origine}/{type}';

  /**
   * Récupération des informations d'un document pour Admin.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocumentAdmin()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentAdmin$Response(params: {
    origine: 'SGF' | 'SWAPART' | 'ADMIN';
    type: TypeDocumentAdmin;
    service?: 'FOURRIERE' | 'FREEFLOW' | 'PVE' | 'FPS';
  }): Observable<StrictHttpResponse<DocumentPubliqueDto>> {

    const rb = new RequestBuilder(this.rootUrl, ServiceDeGestionDesDocumentsService.GetDocumentAdminPath, 'get');
    if (params) {
      rb.path('origine', params.origine, {});
      rb.path('type', params.type, {});
      rb.query('service', params.service, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DocumentPubliqueDto>;
      })
    );
  }

  /**
   * Récupération des informations d'un document pour Admin.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDocumentAdmin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentAdmin(params: {
    origine: 'SGF' | 'SWAPART' | 'ADMIN';
    type: TypeDocumentAdmin;
    service?: 'FOURRIERE' | 'FREEFLOW' | 'PVE' | 'FPS';
  }): Observable<DocumentPubliqueDto> {

    return this.getDocumentAdmin$Response(params).pipe(
      map((r: StrictHttpResponse<DocumentPubliqueDto>) => r.body as DocumentPubliqueDto)
    );
  }

  /**
   * Path part for operation getListDocumentsAccessible
   */
  static readonly GetListDocumentsAccessiblePath = '/documents/accessible';

  /**
   * Récupération des documents administratifs accessible à l'utilisateur SGF ou SWAPART.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getListDocumentsAccessible()` instead.
   *
   * This method doesn't expect any request body.
   */
  getListDocumentsAccessible$Response(params: {
    type: string;
    service: string;
  }): Observable<StrictHttpResponse<Array<DocumentPubliqueDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ServiceDeGestionDesDocumentsService.GetListDocumentsAccessiblePath, 'get');
    if (params) {
      rb.query('type', params.type, {});
      rb.query('service', params.service, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DocumentPubliqueDto>>;
      })
    );
  }

  /**
   * Récupération des documents administratifs accessible à l'utilisateur SGF ou SWAPART.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getListDocumentsAccessible$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getListDocumentsAccessible(params: {
    type: string;
    service: string;
  }): Observable<Array<DocumentPubliqueDto>> {

    return this.getListDocumentsAccessible$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DocumentPubliqueDto>>) => r.body as Array<DocumentPubliqueDto>)
    );
  }

}
