import { NgModule } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDateFormats } from '@angular/material/core';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { HttpErrorInterceptor } from './core/http/http-error';
import { HttpCsrfInterceptor } from './core/http/http-csrf-interceptor';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { HeaderComponent } from './core/components/header/header.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MenuOrBreadcrumbModule } from './shared/components/menu-or-breadcrumb/menu-or-breadcrumb.module';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { NotFoundComponent } from './core/components/not-found/not-found.component';
import { TranslationService } from './core/services/translation.service';
import { RouterModule } from '@angular/router';
import { ConfirmModalComponent } from './shared/components/confirm-modal/confirm-modal.component';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from '@angular/material/dialog';
import { TokenExpiredComponent } from './core/components/token-expired/token-expired.component';
import { FooterComponent } from './core/components/footer/footer.component';
import { AuthGuard } from './core/guards/auth.guard';
import { AntaiMatPaginatorIntl } from './core/providers/antai-mat-paginator-intl';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { AddressApiAutocompleteModule } from './shared/components/address-api-autocomplete/address-api-autocomplete.module';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import {
  ModelesConventionPveModalComponent
} from './shared/components/modeles-convention-pve-modal/modeles-convention-pve-modal.component';
import { ConfirmValidModalComponent } from './shared/components/confirm-valid-modal/confirm-valid-modal.component';
import { AntaiCardModule } from './shared/components/antai-card/antai-card.module';

const FR_FORMAT: MatDateFormats = {
  parse: {
    dateInput: 'L',
  },
  display: {
    dateInput: 'L',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'L',
  },
};

// @dynamic
@NgModule({
  declarations: [
    HeaderComponent,
    NotFoundComponent,
    ConfirmModalComponent,
    ModelesConventionPveModalComponent,
    TokenExpiredComponent,
    FooterComponent,
    ConfirmValidModalComponent
  ],
  imports: [
    HttpClientModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'XSRF-TOKEN',
      headerName: 'X-XSRF-TOKEN'
    }),
    BrowserAnimationsModule,
    TranslateModule.forRoot(),
    FlexLayoutModule,
    MatButtonModule,
    MatSnackBarModule,
    MenuOrBreadcrumbModule,
    MatDialogModule,
    RouterModule.forChild([
      {path: 'page-introuvable', component: NotFoundComponent},
      {path: 'lien-expire', component: TokenExpiredComponent},
      {path: 'session-expire', component: TokenExpiredComponent, canActivate: [AuthGuard]},
      {
        path: 'accessibilite',
        loadChildren: () => import('./lib-accessibility/lib-accessibility-routing.module')
          .then(m => m.LibAccessibilityRoutingModule)
      },
      {
        path: 'donnees-personnelles',
        loadChildren: () => import('./lib-personal-data/lib-personal-data-routing.module')
          .then(m => m.LibPersonalDataRoutingModule)
      }
    ]),
    AddressApiAutocompleteModule,
    MatIconModule,
    MatFormFieldModule,
    MatButtonToggleModule,
    AntaiCardModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpCsrfInterceptor, multi: true },
    {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: FR_FORMAT},
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {strict: true}},
    {provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {scrollStrategy: new NoopScrollStrategy(), hasBackdrop: true}
      },
    {
      provide: MatPaginatorIntl,
      useFactory: (translate) => {
        const service = new AntaiMatPaginatorIntl();
        service.injectTranslateService(translate);
        return service;
      },
      deps: [TranslateService]
    },
    TranslationService
  ],
  exports: [
    HeaderComponent,
    NotFoundComponent,
    ConfirmModalComponent,
    ModelesConventionPveModalComponent,
    TokenExpiredComponent,
    FooterComponent,
  ]
})
export class AntaiCommonModule {}
