import { Component, EventEmitter, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SwaPartAuthService } from '../core/auth/swa-part-auth.service';
import { RoutesEnum } from '../core/enums/routes.enum';
import { RegisterManagerService } from './register/register-manager.service';
import { OverlaysService } from '../../../../common/src/lib/shared/services/overlays.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  templateUrl: './sign.component.html',
  styleUrls: ['./sign.component.scss']
})
export class SignComponent implements OnInit {

  signUpBtnClickEvent = new EventEmitter<Event>();

  constructor(public authenticationService: SwaPartAuthService,
              private registerManagerService: RegisterManagerService,
              private router: Router,
              private overlaysService: OverlaysService,
              private translateService: TranslateService) {

    if (this.authenticationService.gestionSessionExpiree()) {
      this.overlaysService.addSnackBar(this.translateService.instant('expired_session.title'), true);
      localStorage.clear();
    }

    // redirection vers la page home si déja connecté
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit(): void {
    this.signUpBtnClickEvent.subscribe(() => {
      this.goToRegister();
    });
  }

  goToRegister() {
    this.router.navigate([RoutesEnum.REGISTER]).finally(() => {
      this.registerManagerService.initOrReinitForm();
    });
  }
}
