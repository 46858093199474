<p *ngIf="backLink">
  <!-- TODO routerLinkActive à supprimer quand angular sera en version >= 13-->
  <a class="clickable-blue"
     href="#"
     [routerLink]="backLink.tableLink ? null : [backLink.routerLink ? backLink.routerLink : '../']"
     [routerLinkActive]="backLink.tableLink ? 'is_disabled' : 'is_active'"
     (click)="backLink.tableLink ? backToTable() : null">
    {{ backLink.labelKey | translate }}
  </a>
</p>
<h1 class="page-title">{{ pageTitleKeyLabel | translate }}</h1>
<div id="tab-container" fxLayout.gt-xs="row">
  <div fxFlex.gt-xs></div>
  <antai-menu-or-breadcrumb fxFlex.gt-xs="40" [tabOrSteps]="tabs"></antai-menu-or-breadcrumb>
  <div fxFlex.gt-xs>
    <ng-content></ng-content>
  </div>
</div>
