import { Component, Input } from '@angular/core';
import { FooterWithBtn } from './footer-with-btn.model';

@Component({
  selector: 'antai-footer-with-btn',
  templateUrl: './footer-with-btn.component.html',
  styleUrls: ['./footer-with-btn.component.scss']
})
export class FooterWithBtnComponent {

  @Input() value: FooterWithBtn;
  @Input() error: string;

  onSubmit(btn: any) {
    btn.clickEvent?.event.emit(btn.clickEvent.value);
  }
}
