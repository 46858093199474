/* tslint:disable */
/* eslint-disable */

/**
 * Type de service lié à la conversation
 */
export enum TypeService {
  Fps = 'FPS',
  Freeflow = 'FREEFLOW',
  SiFourriere = 'SI_FOURRIERE',
  Pve = 'PVE'
}
