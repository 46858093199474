<div id ="antai-footer" fxLayout="column">
  <div fxLayout="row" *ngFor="let footer of 'footers' | translate; let lastFooter = last">
    <div fxFlex="10"></div>
    <div fxFlex="5" *ngIf="footer.title">
      <p class="titre"> {{ footer.title }} </p>
      <hr>
    </div>
    <div fxFlex>
      <ul class="list-inline">
        <ng-container *ngFor="let link of footer.links">
          <li>
            <ng-container *ngIf="link.isExternalLink || footer.title; else elseIsNotExternalLink">
            <!--Liens externes et site utiles-->
            <a *ngIf="link.isExternalLink || footer.title"
               [ngClass]="footer.title ? 'link-external-icon black' : 'link-external-icon grey'"
               [target]="'_blank'"
               [href]="link.url || 'https://'+link.label"
               [title]="(footer.title ? ('external_link_title' | translate) : '')
                        + link.label
                        + ('new_window' | translate) ">
              {{ link.label | translate }}
            </a>
            </ng-container>

            <!--Liens internes-->
            <ng-template #elseIsNotExternalLink>
              <a [routerLink]="link.url"
                 [title]="link.label">
                {{ link.label | translate }}
              </a>
            </ng-template>
          </li>
        </ng-container>
      </ul>
    </div>
    <p fxFlex="5" *ngIf="!lastFooter" class="version">v{{appVersion}}</p>
    <div fxFlex="5" *ngIf="lastFooter"></div>
  </div>
</div>
