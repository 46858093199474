import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterWithBtnComponent } from './footer-with-btn.component';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';


@NgModule({
  declarations: [FooterWithBtnComponent],
  exports: [
    FooterWithBtnComponent
  ],
    imports: [
        CommonModule,
        TranslateModule,
        MatButtonModule,
        FlexLayoutModule,
        RouterModule,
        MatFormFieldModule,
        MatProgressSpinnerModule,
        MatIconModule
    ]
})
export class FooterWithBtnModule { }
