import { Injectable } from '@angular/core';

import { AuthJwtInterceptor } from 'projects/common/src/lib/core/auth/auth-jwt-interceptor';
import { SwaPartAuthService } from './swa-part-auth.service';

@Injectable()
export class SwaPartAuthJwtInterceptor extends AuthJwtInterceptor {
  constructor(swaPartAuthService: SwaPartAuthService) {
    super(swaPartAuthService);
  }
}
