import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthGuard } from '../../../../../common/src/lib/core/guards/auth.guard';
import { SwaPartAuthService } from '../auth/swa-part-auth.service';

@Injectable({ providedIn: 'root' })
export class SwaPartAuthGuard extends AuthGuard {
  constructor(router: Router, swaPartAuthService: SwaPartAuthService) {
    super(swaPartAuthService, router);
  }
}
