import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AntaiUploadFileModalComponent } from '../components/antai-upload-file/antai-upload-file-modal/antai-upload-file-modal.component';
import { DownloadFileService } from './download-file.service';
import { MatDialog } from '@angular/material/dialog';
import { StrictHttpResponse } from '../../api/api-gestion-acces-sgf/strict-http-response';
import { DocumentPubliqueDto } from '../../api/api-gestion-documents-sgf/models/document-publique-dto';
import { ServiceDeGestionDesDocumentsService } from '../../api/api-gestion-documents-sgf/services/service-de-gestion-des-documents.service';
import { TranslateService } from '@ngx-translate/core';


@Injectable({
  providedIn: 'root'
})

export class OverlaysService {

  maxSizeFileInMegabytes = 5;

  /**
   *  Constructeur
   * @param snackBar Instanciation Snback Bar Material Design (notification en bas de page)
   * @param dialog Instanciation MatDialog Material Design (modale)
   * @param downloadFileService Service gérant les téléchargements
   * @param serviceDeGestionDesDocumentsService  service qui gère les documents
   * @param translateService service de récupération des libellés
   */
  constructor( private snackBar: MatSnackBar,
               private dialog: MatDialog,
               private downloadFileService: DownloadFileService,
               private serviceDeGestionDesDocumentsService: ServiceDeGestionDesDocumentsService,
               private translateService: TranslateService
               ) { }

  addSnackBar(message: string, errorMessage: boolean, duration: number= 10000) {
    this.snackBar.open(message, null, {
      duration: duration,
      panelClass: [errorMessage ? 'snackbar-error' : 'snackbar-info']
    });
  }

  /**
   * showDocument, méthode permettant d'aller chercher le document demandé coté back
   * @param documentPublique, information concernant le document sur lequel on vient de cliquer
   */
  showDocument(documentPublique: DocumentPubliqueDto): void {
    this.serviceDeGestionDesDocumentsService.getFichier$Response({
      type: documentPublique.type,
      service: documentPublique.serviceAssocie
    }).subscribe(
      (blob) => {
      this.showDocumentInModalOrDownload(documentPublique, blob);
    }, (error) => {
      if (error.status === 404) {
        const messageErreur = this.translateService.instant('document_indisponible');
        this.addSnackBar(messageErreur, true);
      }
    });
  }

  showDocumentInModalOrDownload(documentOrPj: any, resp: StrictHttpResponse<Blob>) {
    const file = new File([resp.body], documentOrPj.nomFichier, {
      type: resp.body.type
    });

    const fileSizeInMegabytes = file.size / (1024 * 1024);

    if (documentOrPj.type === 'MODELE_CSV' || fileSizeInMegabytes > this.maxSizeFileInMegabytes) {
      this.downloadFileService.download(resp.body, resp.headers.get('Content-Disposition'), documentOrPj.nomFichier);

    } else {
      this.dialog.open(AntaiUploadFileModalComponent, {
        width: '60%',
        height: '90%',
        panelClass: file.type === 'image/jpeg' ? 'modal-content-center' : '',
        data: file
      });
    }
  }
}
