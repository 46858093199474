import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AntaiCard } from './antai-card.model';

@Component({
  selector: 'antai-card',
  templateUrl: './antai-card.component.html',
  styleUrls: ['./antai-card.component.scss']
})

/**
 * Carte ou « card » SGF. Conteneur flexible et extensible, elle permet de délimiter un grand nombre d'élément
 * graphique présent sur l'application admin comme non-admin.
 * Elle est composée d’un en-tête, d’un corps et d’un pied configurable en instanciant l'objet "AntaiCard".
 */
export class AntaiCardComponent {
  isHtmlRegex = new RegExp('.*[<>].*');
  _value: AntaiCard;

  @Output() iconVisibilityHasClicked: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  set value(test: AntaiCard) {
    if (test) {
      this._value = test;
    }
  }

  get value() {
    return this._value;
  }

  handleClickIconVisibility() {
    this.iconVisibilityHasClicked.emit();
  }

}
