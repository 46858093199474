<div id="full-app-container">
  <div id="header-container">
    <swa-header [authService]="swaPartAuthService" role="menubar"></swa-header>
  </div>
  <div id="page-container" role="main">
    <!-- main app container -->
    <router-outlet></router-outlet>
  </div>
</div>
<div id="footer-container">
  <antai-footer role="contentinfo"></antai-footer>
</div>
