/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ConversationDto } from '../models/conversation-dto';
import { ListeConversationDto } from '../models/liste-conversation-dto';
import { MessageDto } from '../models/message-dto';
import { Sujet } from '../models/sujet';
import { TypeService } from '../models/type-service';


/**
 * Service de messagerie Controller
 */
@Injectable({
  providedIn: 'root',
})
export class MessagerieService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation postConversation
   */
  static readonly PostConversationPath = '/conversation';

  /**
   * Création d’une nouvelle conversation.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postConversation()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postConversation$Response(params: {
    body: ConversationDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MessagerieService.PostConversationPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Création d’une nouvelle conversation.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postConversation$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postConversation(params: {
    body: ConversationDto
  }): Observable<void> {

    return this.postConversation$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getConversation
   */
  static readonly GetConversationPath = '/messages/{idConversation}';

  /**
   * Récupération du contenu d’une conversation.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConversation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConversation$Response(params: {
    idConversation: number;
  }): Observable<StrictHttpResponse<ConversationDto>> {

    const rb = new RequestBuilder(this.rootUrl, MessagerieService.GetConversationPath, 'get');
    if (params) {
      rb.path('idConversation', params.idConversation, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ConversationDto>;
      })
    );
  }

  /**
   * Récupération du contenu d’une conversation.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getConversation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConversation(params: {
    idConversation: number;
  }): Observable<ConversationDto> {

    return this.getConversation$Response(params).pipe(
      map((r: StrictHttpResponse<ConversationDto>) => r.body as ConversationDto)
    );
  }

  /**
   * Path part for operation postMessage
   */
  static readonly PostMessagePath = '/message/{idConversation}';

  /**
   * Créer un nouveau message dans une conversation existante.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postMessage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postMessage$Response(params: {
    idConversation: number;
    body?: MessageDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MessagerieService.PostMessagePath, 'post');
    if (params) {
      rb.path('idConversation', params.idConversation, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Créer un nouveau message dans une conversation existante.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postMessage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postMessage(params: {
    idConversation: number;
    body?: MessageDto
  }): Observable<void> {

    return this.postMessage$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getConversations
   */
  static readonly GetConversationsPath = '/conversations';

  /**
   * Récupération de la liste des conversations en fonction du token Bearer.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConversations()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConversations$Response(params: {
    typeService?: TypeService;
    sujet?: Sujet;
    objet?: string;
    auteur?: string;
    organisation?: string;
    numeroPage: number;
    desc: boolean;
    tri: 'SERVICE' | 'SUJET' | 'OBJET' | 'DATE' | 'AUTEUR' | 'ORGANISATION' | 'NON_LU';
    elementParPage: number;
    dateDebut?: string;
    dateFin?: string;
    statut?: 'A_TRAITER' | 'EN_COURS' | 'SUSPENDU' | 'CLOS' | 'TOUS_SAUF_CLOS';
  }): Observable<StrictHttpResponse<ListeConversationDto>> {

    const rb = new RequestBuilder(this.rootUrl, MessagerieService.GetConversationsPath, 'get');
    if (params) {
      rb.query('typeService', params.typeService, {});
      rb.query('sujet', params.sujet, {});
      rb.query('objet', params.objet, {});
      rb.query('auteur', params.auteur, {});
      rb.query('organisation', params.organisation, {});
      rb.query('numeroPage', params.numeroPage, {});
      rb.query('desc', params.desc, {});
      rb.query('tri', params.tri, {});
      rb.query('elementParPage', params.elementParPage, {});
      rb.query('dateDebut', params.dateDebut, {});
      rb.query('dateFin', params.dateFin, {});
      rb.query('statut', params.statut, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListeConversationDto>;
      })
    );
  }

  /**
   * Récupération de la liste des conversations en fonction du token Bearer.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getConversations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConversations(params: {
    typeService?: TypeService;
    sujet?: Sujet;
    objet?: string;
    auteur?: string;
    organisation?: string;
    numeroPage: number;
    desc: boolean;
    tri: 'SERVICE' | 'SUJET' | 'OBJET' | 'DATE' | 'AUTEUR' | 'ORGANISATION' | 'NON_LU';
    elementParPage: number;
    dateDebut?: string;
    dateFin?: string;
    statut?: 'A_TRAITER' | 'EN_COURS' | 'SUSPENDU' | 'CLOS' | 'TOUS_SAUF_CLOS';
  }): Observable<ListeConversationDto> {

    return this.getConversations$Response(params).pipe(
      map((r: StrictHttpResponse<ListeConversationDto>) => r.body as ListeConversationDto)
    );
  }

  /**
   * Path part for operation getNombreMessageNonLu
   */
  static readonly GetNombreMessageNonLuPath = '/messagesnonlu';

  /**
   * Récupération du nombre de message non lu.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNombreMessageNonLu()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNombreMessageNonLu$Response(params?: {
  }): Observable<StrictHttpResponse<number>> {

    const rb = new RequestBuilder(this.rootUrl, MessagerieService.GetNombreMessageNonLuPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * Récupération du nombre de message non lu.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getNombreMessageNonLu$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNombreMessageNonLu(params?: {
  }): Observable<number> {

    return this.getNombreMessageNonLu$Response(params).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

  /**
   * Path part for operation getPieceJointe
   */
  static readonly GetPieceJointePath = '/pieceJointe/{idPieceJointe}';

  /**
   * Récupération d'une pièce jointe par son id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPieceJointe()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPieceJointe$Response(params: {

    /**
     * Récupération de la pièce jointe liée à un message
     */
    idPieceJointe: number;
  }): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, MessagerieService.GetPieceJointePath, 'get');
    if (params) {
      rb.path('idPieceJointe', params.idPieceJointe, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'multipart/form-data'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Récupération d'une pièce jointe par son id.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPieceJointe$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPieceJointe(params: {

    /**
     * Récupération de la pièce jointe liée à un message
     */
    idPieceJointe: number;
  }): Observable<Blob> {

    return this.getPieceJointe$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

}
