export enum ConventionStatus {
  ACCEPTE = 'ACCEPTE',
  A_TRAITER = 'A_TRAITER',
  ERREUR = 'ERREUR',
  REFUSE = 'REFUSE',
  DESACTIVE = 'DESACTIVE',
  REPRISE = 'REPRISE',
  SOUMISE_APPROBATION = 'SOUMISE_APPROBATION',
  SOUMISE_SIGNATURE = 'SOUMISE_SIGNATURE',
  VALIDE = 'VALIDE',
  ARRIVE_A_EXPIRATION = 'ARRIVE_A_EXPIRATION',
  EXPIRE = 'EXPIRE',
  ABSENT = 'ABSENT',
  EN_COURS = 'EN_COURS'
}
