import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SgfAuthentificationService } from 'projects/common/src/lib/api/api-gateway-sgf/services/sgf-authentification.service';
import { AuthService } from 'projects/common/src/lib/core/auth/auth.service';
import { ConventionDto } from '../../../../../common/src/lib/api/api-gestion-acces-sgf/models/convention-dto';
import { CompteDto } from '../../../../../common/src/lib/api/api-gestion-acces-sgf/models/compte-dto';

@Injectable({providedIn: 'root'})
export class SwaPartAuthService extends AuthService {


  constructor(
    protected router: Router,
    protected sgfAuthentificationService: SgfAuthentificationService
  ) {
    super(sgfAuthentificationService, router);
  }

  public get currentUserAccountValue(): CompteDto {
    return JSON.parse(localStorage.getItem('currentAccount'));
  }
  public set currentUserAccountValue(account: CompteDto) {
    localStorage.setItem('currentAccount', JSON.stringify(account));
  }

  redirectAccordingAccountType(url: string): boolean {
    return true;
  }

  redirectAccordingStatus() {
    this.router.navigate(['/']);
  }
}
