// Harmonisation de la REGEX
export const emailPattern = /^[a-zA-Z0-9.!'#$%&*+/=?^_`\{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;
export const phonePattern = /^0[1-9]\d{8}$/i;
export const zipCodePattern = /^\d{5}$/i;
export const inseeCodePattern = /^\w{5,9}$/i;
export const siretPattern = /^\d{14}\b/i;
// au moins 8 caractères, 1 chiffre, 1 lettre majuscule et 1 lettre minuscule, 1 caractère spécial
export const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$&+,:;=?@#|'<>.^*()%!-])[A-Za-z\d$&+,:;=?@#|'<>.^*()%!-]{8,}$/;
// authorise les caractères alphanumeriques et les espaces mais pas les caractères spéciaux
export const serviceCodeFacturationPattern = /^[\w\s]+$/i;
export const nombresPositif = /^[0-9]\d*$/;
export const nombresPositifOneMin = /^[1-9]\d*$/;
