export enum RoutesEnum {
  SIGN_IN = 'connexion',

  // process inscription
  REGISTER = 'inscription',
  IDENTIFICATION = 'identifiant-societe',
  ORGANISATION = 'informations-organisation',
  CONTACT = 'vos-informations',
  RECAP = 'confirmation',
  END = 'fin',

  // Docummentation PVE
  DOCUMENTATION_PVE = 'documentation-pve',
  VERBALIZATION = 'verbalisation',
  EQUIPMENT = 'equipement-verbalisation',
  NOTICE = 'information-releve',
  FOLLOWED= 'verbalisation-statistique',
  RECOMMENDATIONS = 'recommandations',
  TEXTS = 'textes-reglementaires-infractions',

  // espace connecté
  PARTNER_SPACE = 'espace-partenaires',
  ACCOUNT = 'mon-compte',
  SERVICE = 'mon-service',
  FOURRIERE = 'fourriere',
  FREEFLOW = 'freeflow',
  PVE = 'pve',
  FPS = 'fps',
  DOCUMENTS = 'documents',
  MA_MESSAGERIE = 'ma-messagerie',
  MA_FACTURATION = 'ma-facturation',
  MA_FACTURATION_INFO_FOURRIERE= 'ma-facturation-info-fourriere',
  MA_FACTURATION_INFO_FPS= 'ma-facturation-info-fps',
  MA_FACTURATION_INFO_FREEFLOW= 'ma-facturation-info-freeflow',
  MA_FACTURATION_MES_FACTURES = 'ma-facturation-mes-factures',

  // parcours de conventionnement
  AGREEMENT = 'conventionnement',
  AGREEMENT_PRE_REGISTATION = 'pre-enregistrement',
  AGREEMENT_UPLOAD_STEP = 'chargement-convention',
  AGREEMENT_CHANGE_PROVIDER = 'changement-prestataire',
  AGREEMENT_CERTIFICATES_PVE = 'certificatsPVe',
  AGREEMENT_CERTIFICATES_FPS = 'certificatsFPS',
  AGREEMENT_PERSONALISATION_FPS = 'personnalisationFPS',
  AGREEMENT_VALIDATION = 'validation',

  // messagerie
  NEW_MESSAGE = 'nouveau-message',
  MESSAGES = 'messages'

}
