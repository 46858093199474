import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ApiDeGestionDesFfService } from '../../api/api-gestion-acces-sgf/services/api-de-gestion-des-ff.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GestionFeatureFlippingService {

  public readonly FF_LOUEUR = 'FF_LOUEUR';
  public readonly FF_FACTURATION = 'FF_FACTURATION';
  public readonly FF_FACTURATION_V2 = 'FF_FACTURATION_V2';
  public readonly FF_MESSAGERIE = 'FF_MESSAGERIE';
  public readonly FF_VEHICULES_SPECIAUX = 'FF_VEHICULES_SPECIAUX';
  public readonly FF_FACTURATION_FREEFLOW = 'FF_FACTURATION_FREEFLOW';
  public readonly FF_FACTURATION_FOURRIERE = 'FF_FACTURATION_FOURRIERE';
  public readonly FF_FACTURATION_FPS = 'FF_FACTURATION_FPS';

  /**
   * Cache de clés
   */
  private readonly clesConnues: Map<string, boolean> = new Map<string, boolean>();

  constructor(private readonly apiDeGestionDesFfService: ApiDeGestionDesFfService) {
    this.getAllFeatureFlippingStatus();
  }

  /**
   * checkFF, vérifie une clé de feature-flipping.
   * La méthode vérifie d'abord la présence de la clé dans clesConnues.
   * Si elle n'y est pas, on appelle le service apiDeGestionDesFfService.checkFf puis on l'ajoute dans clesConnues.
   * @param cle (string) nom de la fonctionnalité
   * @return Renvoie un Observable de booléen. faux par défaut (clé vide, null ou inconnue...)
   **/
  public checkFF(cle: string): Observable<boolean> {
    if (!cle) {
      return of(false);
    } else if (this.clesConnues.has(cle)) {
      return of(this.clesConnues.get(cle) ?? false);
    } else {
      return this.apiDeGestionDesFfService.checkFf({key: cle}).pipe(
        map(reponse => {
          this.clesConnues.set(cle, reponse.value);
          return reponse.value;
        })
      );
    }
  }

  /**
   * getAllFeatureFlippingStatus, initialise les variables global des clés de feature flipping
   */
  private getAllFeatureFlippingStatus(): void {
    this.getCleLoueur().subscribe();
    this.getCleFacturation().subscribe();
    this.getCleFacturationV2().subscribe();
    this.getCleMessagerie().subscribe();
    this.getCleVehiculesSpeciaux().subscribe();
    this.getCleFacturationFreeflow().subscribe();
    this.getCleFacturationFourriere().subscribe();
    this.getCleFacturationFPS().subscribe();
  }

  /**
   * getCleLoueur, récupère la valeur de la cle n'importe ou dans l'application
   */
  public getCleLoueur(): Observable<boolean> {
    return this.checkFF(this.FF_LOUEUR);
  }
  /**
   * getCleFacturation, récupère la valeur de la cle n'importe ou dans l'application
   */
  public getCleFacturation(): Observable<boolean> {
    return this.checkFF(this.FF_FACTURATION);
  }
  /**
   * getCleFacturationV2, récupère la valeur de la cle n'importe ou dans l'application
   */
  public getCleFacturationV2(): Observable<boolean> {
    return this.checkFF(this.FF_FACTURATION_V2);
  }
  /**
   * getCleMessagerie, récupère la valeur de la cle n'importe ou dans l'application
   */
  public getCleMessagerie(): Observable<boolean> {
    return this.checkFF(this.FF_MESSAGERIE);
  }
  /**
   * getCleVehiculesSpeciaux, récupère la valeur de la cle n'importe ou dans l'application
   */
  public getCleVehiculesSpeciaux(): Observable<boolean> {
    return this.checkFF(this.FF_VEHICULES_SPECIAUX);
  }
  /**
   * getCleFacturationFreeflow, récupère la valeur de la cle n'importe ou dans l'application
   */
  public getCleFacturationFreeflow(): Observable<boolean> {
    return this.checkFF(this.FF_FACTURATION_FREEFLOW);
  }
  /**
   * getCleFacturationFourriere, récupère la valeur de la cle n'importe ou dans l'application
   */
  public getCleFacturationFourriere(): Observable<boolean> {
    return this.checkFF(this.FF_FACTURATION_FOURRIERE);
  }
  /**
   * getCleFacturationFPS, récupère la valeur de la cle n'importe ou dans l'application
   */
  public getCleFacturationFPS(): Observable<boolean> {
    return this.checkFF(this.FF_FACTURATION_FPS);
  }
}
