/* tslint:disable */
/* eslint-disable */

/**
 * Enum regroupant les différents types d'organisation swapart
 */
export enum TypeOrganisationEnum {
  Sca = 'SCA',
  Collectivite = 'COLLECTIVITE',
  EntreprisePrive = 'ENTREPRISE_PRIVE',
  TransportPublique = 'TRANSPORT_PUBLIQUE',
  Autre = 'AUTRE',
  PoliceEnvironnement = 'POLICE_ENVIRONNEMENT'
}
