/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ConversationAdminDto } from '../models/conversation-admin-dto';
import { ListeConversationDto } from '../models/liste-conversation-dto';
import { StatutConversation } from '../models/statut-conversation';
import { Sujet } from '../models/sujet';
import { TypeService } from '../models/type-service';


/**
 * Service de messagerie Controller pour les Admins
 */
@Injectable({
  providedIn: 'root',
})
export class MessagerieAdminService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation postConversationAdmin
   */
  static readonly PostConversationAdminPath = '/conversationAdmin';

  /**
   * Création d’une nouvelle conversation coté admin.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postConversationAdmin()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postConversationAdmin$Response(params: {
    body: ConversationAdminDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MessagerieAdminService.PostConversationAdminPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Création d’une nouvelle conversation coté admin.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postConversationAdmin$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postConversationAdmin(params: {
    body: ConversationAdminDto
  }): Observable<void> {

    return this.postConversationAdmin$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation modifierConversationAdmin
   */
  static readonly ModifierConversationAdminPath = '/admin/modifierConversation';

  /**
   * Modifier une de conversations par l'admin.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `modifierConversationAdmin()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  modifierConversationAdmin$Response(params: {
    body: ConversationAdminDto
  }): Observable<StrictHttpResponse<ConversationAdminDto>> {

    const rb = new RequestBuilder(this.rootUrl, MessagerieAdminService.ModifierConversationAdminPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ConversationAdminDto>;
      })
    );
  }

  /**
   * Modifier une de conversations par l'admin.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `modifierConversationAdmin$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  modifierConversationAdmin(params: {
    body: ConversationAdminDto
  }): Observable<ConversationAdminDto> {

    return this.modifierConversationAdmin$Response(params).pipe(
      map((r: StrictHttpResponse<ConversationAdminDto>) => r.body as ConversationAdminDto)
    );
  }

  /**
   * Path part for operation getNombreConversationParStatut
   */
  static readonly GetNombreConversationParStatutPath = '/admin/nombreConversationParStatut/{statutConversation}';

  /**
   * Remonter le nombre de conversations par statut .
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNombreConversationParStatut()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNombreConversationParStatut$Response(params: {
    statutConversation: StatutConversation;
  }): Observable<StrictHttpResponse<number>> {

    const rb = new RequestBuilder(this.rootUrl, MessagerieAdminService.GetNombreConversationParStatutPath, 'get');
    if (params) {
      rb.path('statutConversation', params.statutConversation, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * Remonter le nombre de conversations par statut .
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getNombreConversationParStatut$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNombreConversationParStatut(params: {
    statutConversation: StatutConversation;
  }): Observable<number> {

    return this.getNombreConversationParStatut$Response(params).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

  /**
   * Path part for operation getConversationsByIdOrganisation
   */
  static readonly GetConversationsByIdOrganisationPath = '/conversations/{idOrganisation}';

  /**
   * Récupération de la liste des conversations en fonction de l'id organisation.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConversationsByIdOrganisation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConversationsByIdOrganisation$Response(params: {
    idOrganisation: number;
    typeService?: TypeService;
    sujet?: Sujet;
    objet?: string;
    numeroPage: number;
    desc: boolean;
    tri: 'SERVICE' | 'SUJET' | 'OBJET' | 'DATE' | 'AUTEUR' | 'ORGANISATION' | 'NON_LU';
  }): Observable<StrictHttpResponse<ListeConversationDto>> {

    const rb = new RequestBuilder(this.rootUrl, MessagerieAdminService.GetConversationsByIdOrganisationPath, 'get');
    if (params) {
      rb.path('idOrganisation', params.idOrganisation, {});
      rb.query('typeService', params.typeService, {});
      rb.query('sujet', params.sujet, {});
      rb.query('objet', params.objet, {});
      rb.query('numeroPage', params.numeroPage, {});
      rb.query('desc', params.desc, {});
      rb.query('tri', params.tri, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListeConversationDto>;
      })
    );
  }

  /**
   * Récupération de la liste des conversations en fonction de l'id organisation.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getConversationsByIdOrganisation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConversationsByIdOrganisation(params: {
    idOrganisation: number;
    typeService?: TypeService;
    sujet?: Sujet;
    objet?: string;
    numeroPage: number;
    desc: boolean;
    tri: 'SERVICE' | 'SUJET' | 'OBJET' | 'DATE' | 'AUTEUR' | 'ORGANISATION' | 'NON_LU';
  }): Observable<ListeConversationDto> {

    return this.getConversationsByIdOrganisation$Response(params).pipe(
      map((r: StrictHttpResponse<ListeConversationDto>) => r.body as ListeConversationDto)
    );
  }

}
