import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AntaiCommonModule } from '../../../common/src/lib/antai-common.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MenuOrBreadcrumbModule } from 'projects/common/src/lib/shared/components/menu-or-breadcrumb/menu-or-breadcrumb.module';
import { HeaderComponent } from './core/header/header.component';
import { AntaiCardModule } from '../../../common/src/lib/shared/components/antai-card/antai-card.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { FooterWithBtnModule } from '../../../common/src/lib/shared/components/footer-with-btn/footer-with-btn.module';
import { MatSelectModule } from '@angular/material/select';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { appInitializer } from '../../../common/src/lib/core/app-initializer';
import { SignInCardComponent } from './sign/sign-in-card/sign-in-card.component';
import { SignComponent } from './sign/sign.component';
import { SwaPartAuthJwtInterceptor } from './core/auth/swa-part-auth-jwt-interceptor';
import { SwaPartAuthService } from './core/auth/swa-part-auth.service';
import { FeatureFlippingModule } from '../../../common/src/lib/shared/components/feature-flipping/feature-flipping.module';
import { ChildrenTabsMenuModule } from '../../../common/src/lib/shared/components/children-tabs-menu/children-tabs-menu.module';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SignInCardComponent,
    SignComponent
  ],
  imports: [
    AntaiCommonModule,
    AntaiCardModule,
    MatSelectModule,
    FooterWithBtnModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    AppRoutingModule,
    TranslateModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MenuOrBreadcrumbModule,
    FeatureFlippingModule,
    ChildrenTabsMenuModule
  ],

  providers: [
    { provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [SwaPartAuthService] },
    { provide: HTTP_INTERCEPTORS, useClass: SwaPartAuthJwtInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

