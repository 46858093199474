<div *ngIf="error">
  <mat-error>
    {{error}}
  </mat-error>
</div>
<div *ngIf="value.leftBtn || value.centerBtn"
     [ngClass]="{'center': value.centerBtn}"
     class="inline spinner-btn-container">
  <button (click)="onSubmit(value.centerBtn || value.leftBtn)"
          [disabled]="value.centerBtn ? value.centerBtn.disabled : value.leftBtn.disabled"
          [ngClass]="[value.centerBtn ? 'btn-' + value.centerBtn.color : 'btn-' + value.leftBtn.color, value.centerBtn?.smallLabel? 'button-with-small-label' :  '']"
          class="antai-submit-btn"
          mat-flat-button>
    <mat-icon *ngIf="value.iconLabelId">{{ value.iconLabelId }}</mat-icon>
    <span>{{ value.leftBtn ? (value.leftBtn.label | translate) : (value.centerBtn.label | translate) }}</span>
    <span *ngIf="value.centerBtn?.smallLabel" class="small-label">
      <br>
      {{value.centerBtn.smallLabel | translate }}
    </span>
  </button>
  <div *ngIf="(value.leftBtn && value.leftBtn.loading) || (value.centerBtn && value.centerBtn.loading)"
       class="spinner-container">
    <mat-spinner diameter="24"></mat-spinner>
  </div>
</div>
<div *ngIf="value.links" class="inline link">
  <a (click)="onSubmit(link)"
     *ngFor="let link of value.links"
     [routerLink]="link.routePath ? [link.routePath]: []"
     class="clickable-blue"
     href="#">
    {{ link.label | translate }}
  </a>
</div>
<div *ngIf="value.rightBtn" class="inline right-btn spinner-btn-container">
  <button (click)="onSubmit(value.rightBtn)" [disabled]="value.rightBtn.disabled"
          [ngClass]="'btn-' + value.rightBtn.color"
          class="antai-submit-btn "
          mat-flat-button>
    <mat-icon *ngIf="value.iconLabelId">{{ value.iconLabelId }}</mat-icon>
    <span>{{ value.rightBtn.label | translate }}</span>
  </button>
  <div *ngIf="value.rightBtn.loading" class="spinner-container">
    <mat-spinner diameter="24"></mat-spinner>
  </div>
</div>
