import { Btn } from './btn.model';
import { EventEmitter } from '@angular/core';

export class FooterWithBtn {
  leftBtn?: Btn;
  rightBtn?: Btn;
  links?: {label: string, routePath?: string, clickEvent?: {event: EventEmitter<any>, value?: any}}[];
  centerBtn?: Btn;
  iconLabelId?: string;

  constructor(leftBtn?: Btn,
              rightBtn?: Btn,
              links?: [{label: string, routePath?: string, clickEvent?: {event: EventEmitter<any>, value?: any}}],
              centerBtn?: Btn,
              iconLabelId?: string
  ) {
    this.leftBtn = leftBtn;
    this.rightBtn = rightBtn;
    this.links = links;
    this.centerBtn = centerBtn;
    this.iconLabelId = iconLabelId;
  }
}
