import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TypeOrganisationEnum } from '../../../api/api-gestion-acces-sgf/models/type-organisation-enum';
import { SwaPartAuthService } from '../../../../../../swa-part/src/app/core/auth/swa-part-auth.service';
import { TypeDocumentAdmin } from '../../../api/api-gestion-documents-sgf/models/type-document-admin';
import { DownloadFileService } from '../../services/download-file.service';

@Component({
  templateUrl: './modeles-convention-pve-modal.component.html',
  styleUrls: ['./modeles-convention-pve-modal.component.scss']
})
export class ModelesConventionPveModalComponent {

  private readonly documentsCollectivites = [
    {label: 'Modèle de convention PM et EPC1', typeDocument: TypeDocumentAdmin.ModeleConventionPmEpc1},
    {label: 'Modèle de convention Pluricommunalité', typeDocument: TypeDocumentAdmin.ModeleConventionPluricommunalite}
  ];

  private readonly documentsEntreprisePrive = [
    {label: 'Modèle de convention Transport public', typeDocument: TypeDocumentAdmin.ModeleConventionTransportPublic},
    {label: 'Modèle de convention Etablissements publics', typeDocument: TypeDocumentAdmin.ModeleConventionEtablissementPublic},
    {label: 'Modèle de convention Entités privées', typeDocument: TypeDocumentAdmin.ModeleConventionEntitePrivee},
    {label: 'Modèle de convention OFB', typeDocument: TypeDocumentAdmin.ModeleConventionOfb}
  ];

  documents: {label: string, typeDocument: TypeDocumentAdmin}[] = [];

  /**
   * Constructeur
   * @param data
   * @param { MatDialogRef<ModelesConventionPveModalComponent> } dialogRef
   * @param authService
   * @param downloadFileService
   */
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private dialogRef: MatDialogRef<ModelesConventionPveModalComponent>,
              private authService: SwaPartAuthService,
              private downloadFileService: DownloadFileService,
  ) {
    if (authService.currentUserAccountValue.infosOrganisation.typeOrganisation === TypeOrganisationEnum.Collectivite) {
      this.documents = this.documentsCollectivites;
    } else if (authService.currentUserAccountValue.infosOrganisation.typeOrganisation === TypeOrganisationEnum.EntreprisePrive) {
      this.documents = this.documentsEntreprisePrive;
    }
  }



  download(typeDocument: TypeDocumentAdmin) {
    this.downloadFileService.getOrDownloadDocument(typeDocument, 'PVE');
    this.dialogRef.close();
  }

}


