import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map, mergeMap } from 'rxjs/operators';
import { User } from 'projects/common/src/lib/shared/models/user.model';
import { AuthService } from '../../auth/auth.service';
import { GestionFeatureFlippingService } from '../../../shared/services/Gestion-feature-flipping.service';
import { StatutCompte } from '../../../api/api-gestion-acces-sgf/models/statut-compte';
import { AdminRoutesName } from '../../../../../../admin/src/app/shared/enums/admin-routes-name';
import { AccountType } from '../../../shared/enums/account-type.enum';
import { timer } from 'rxjs';
import { AntaiMessagerieService } from '../../../shared/components/messagerie/antai-messagerie.service';

@Component({
  selector: 'antai-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  loggedTabs: Array<{ label: string, routerLink: string, condition: string[] }>;
  // TODO : FF Messagerie Feature Flipping
  ffMessagerie: boolean;

  displayMessageIcon: boolean;
  nbNewMessages: number;

  @Input() authService: AuthService;

  adminRoutesName = AdminRoutesName;

  constructor(private translateService: TranslateService,
              private antaiMessagerieService: AntaiMessagerieService,
              private gestionFeatureFlippingService: GestionFeatureFlippingService) {
  }

  ngOnInit(): void {
    if (this.authService) {
      this.authService.currentUser.subscribe((user: User) => {
        if (user && (user.statutCompte === StatutCompte.Valide || user.statutCompte === StatutCompte.Inactif)) {
          this.displayMessageIcon = user.role === AccountType.SWA_PART_BO ||
            user.role === AccountType.ADMIN ||
            user.role === AccountType.BACK_OFFICE ||
            user.role === AccountType.FACTURATION_BO;

          this.translateService.get('header.logged_tabs')
            .pipe(map((valuesObject: {}) => Object.values(valuesObject)))
            .subscribe((value: {
                label: string,
                routerLink: string,
                condition: string[],
                conditionStatutConvention: string[],
                conditionEligibleAPIFlotteConventionnee: boolean | any,
                hidden: boolean
            }[]) => {

                this.loggedTabs = new Array<{ label: string, routerLink: string, condition: string[] }>();

                // Variable figeant la position de l'onglet conformément au fichier toml
                let position = 0;

                for (const tab of value) {

                  if ((tab.condition === undefined
                      || (tab.condition.includes(user.typeSociete) || tab.condition.includes(user.role))
                      )
                    && (tab.conditionStatutConvention === undefined
                      || user.statutConvention === ''
                      || tab.conditionStatutConvention.includes(user.statutConvention)
                      )
                    && (tab.conditionEligibleAPIFlotteConventionnee === undefined
                      || (tab.conditionEligibleAPIFlotteConventionnee && user.accesApi)
                    )
                    && (tab.label !== 'MESSAGERIE')
                    && (tab.label !== 'FACTURATION') // FF_FACTURATION
                    && (tab.label !== 'VÉHICULES SPÉCIAUX')
                  ) {
                    this.loggedTabs.splice(position, 0, tab);
                  }

                  // FF MESSAGERIE
                  if (tab.label === 'MESSAGERIE' && (tab.condition && tab.condition.includes(user.role))) {
                    const positionMessagerie = position;
                    this.gestionFeatureFlippingService.getCleMessagerie().subscribe((valueFF) => {
                      if (valueFF && !this.loggedTabs.find(e => e.label === 'MESSAGERIE')) {
                        this.loggedTabs.splice(positionMessagerie, 0, tab);
                        this.ffMessagerie = true;
                        this.antaiMessagerieService.nbConversationsATraiter.subscribe({
                          next: (nombre) => {
                            this.nbNewMessages = nombre;
                          }
                        });
                        setTimeout(() => {
                          this.getPeriodiquementNombreConversationsATraiter();
                        }, 250);
                      } else {
                        this.ffMessagerie = false;
                      }
                    });
                  }

                  // FF_FACTURATION
                  if (tab.label === 'FACTURATION' && (tab.condition && tab.condition.includes(user.role))) {
                    const positionFactu = position;
                    this.gestionFeatureFlippingService.getCleFacturation().subscribe((valueFF) => {
                      if (valueFF && !this.loggedTabs.find(e => e.label === 'FACTURATION')) {
                        this.loggedTabs.splice(positionFactu, 0, tab);
                      }
                    });
                  }

                  // FF_VEHICULES_SPECIAUX
                  if (tab.label === 'VÉHICULES SPÉCIAUX' && (tab.condition && tab.condition.includes(user.role))) {
                    const positionVehiculesSpeciaux = position;
                    this.gestionFeatureFlippingService.getCleVehiculesSpeciaux().subscribe((valueFF) => {
                      if (valueFF && !this.loggedTabs.find(e => e.label === 'VÉHICULES SPÉCIAUX')) {
                        this.loggedTabs.splice(positionVehiculesSpeciaux, 0, tab);
                      }
                    });
                  }
                  position++;
                }

              }
            );
        } else {
          this.loggedTabs = null;
        }
      });
    }
  }

  deconnexion() {
    this.authService.logout();
  }

  /**
   * Appel du webservice permettant d'obtenir le nombre de conversations à traiter toutes les minutes
   */
  getPeriodiquementNombreConversationsATraiter() {
    timer(0, 60000).subscribe({
      next: () => this.antaiMessagerieService.rafraichirNombreConversationsATraiter()
    });
  }

}
