/* tslint:disable */
/* eslint-disable */

/**
 * Statut des comptes
 */
export enum StatutCompte {
  AControler = 'A_CONTROLER',
  Refuse = 'REFUSE',
  Valide = 'VALIDE',
  Desactive = 'DESACTIVE',
  EnAttenteFinalisation = 'EN_ATTENTE_FINALISATION',
  Inactif = 'INACTIF',
  Absent = 'ABSENT',
  ArriveAExpiration = 'ARRIVE_A_EXPIRATION',
  ATraiter = 'A_TRAITER',
  Expire = 'EXPIRE'
}
