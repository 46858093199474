import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FooterWithBtnModule } from '../footer-with-btn/footer-with-btn.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { AntaiCardComponent } from './antai-card.component';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';


@NgModule({
  declarations: [AntaiCardComponent],
  exports: [
    AntaiCardComponent
  ],
    imports: [
        CommonModule,
        TranslateModule,
        FooterWithBtnModule,
        FlexLayoutModule,
        RouterModule,
        MatIconModule,
        MatTooltipModule
    ]
})
export class AntaiCardModule { }
