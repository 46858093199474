<!-- Titre de la modale -->
<h2 mat-dialog-title>{{ 'agreement_upload.show_models' | translate}}</h2>

<!-- Message d'info -->
<div mat-dialog-content>
  <ul>
    <li *ngFor="let doc of documents">
      <a (click)="download(doc.typeDocument)" class="clickable-blue">
        {{ doc.label | translate }}
      </a>
    </li>
  </ul>
</div>

<!-- Bouton de confirmation -->
<div mat-dialog-actions>
  <button mat-flat-button class="btn-white antai-submit-btn right-btn" mat-dialog-close>
    {{ 'close' | translate}}
  </button>
</div>
