import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AntaiCard } from '../antai-card/antai-card.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'antai-confirm-valid-modal',
  templateUrl: './confirm-valid-modal.component.html',
  styleUrls: ['./confirm-valid-modal.component.scss']
})
export class ConfirmValidModalComponent implements OnInit {

  card = new AntaiCard(
    'modal_label_info_user',
    'shaded grey',
    false,
    undefined,
    '',
    undefined,
    {
      layoutType: 'column', lists: []
    }
  );

  tabCard: { label: string, value: string } [] = [];

  /**
   * Constructeur
   * @param data
   * @param translateService
   */
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private translateService: TranslateService) {
  }

  ngOnInit(): void {
    const premierUtilisateur = this.data.formData;

    // si swapart
    if ( this.data.typeAccount === 'SWAPART') {
      this.tabCard = [
        {label: 'field.civility', value: premierUtilisateur.civilite},
        {label: 'field.lastname', value: premierUtilisateur.nom},
        {label: 'field.firstname', value: premierUtilisateur.prenom},
        {label: 'field.mail', value: premierUtilisateur.email},
        {label: 'field.function', value: premierUtilisateur.fonction},
        {label: 'field.service', value: premierUtilisateur.service},
        {label: 'field.mobile_phone', value: premierUtilisateur.telephonePortable},
        {label: 'field.home_phone', value: premierUtilisateur.telephoneFixe}
      ];
    }

    if ( this.data.typeAccount === 'SGF') {
      this.tabCard = [
        {label: 'field.lastname', value: premierUtilisateur.nom},
        {label: 'field.firstname', value: premierUtilisateur.prenom},
        {label: 'field.mail', value: premierUtilisateur.email},
        {label: 'field.mobile_phone', value: premierUtilisateur.telephonePortable},
        {label: 'field.home_phone', value: premierUtilisateur.telephoneFixe}
      ];
    }
    this.initCard();
  }

  initCard() {
    for (const item of this.tabCard) {
      this.card.blockLists.lists.push({
        rowType: '',
        title: this.translateService.instant(item.label),
        values: [item.value]
      });
    }
  }
}
