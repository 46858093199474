import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
// @ts-ignore
import * as translation_common from '../../../assets/i18n/fr.toml';
import { deepMerge } from '../../shared/utils/object.util';

@Injectable()
export class TranslationService extends TranslateService {

  /**
   * Initialisation of language
   */
  InitLocalLanguage(translation_app: any) {
    this.addLangs(['fr']);
    this.setDefaultLang('fr');
    const allTranslation = deepMerge({...translation_common}, {...translation_app});
    this.setTranslation('fr', allTranslation);
    this.use( 'fr');
  }
}
