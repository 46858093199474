<div class="card-container"
     *ngIf="value"
     [ngClass]="[value.color, value.isInline ? 'inline-card' : '']"
     fxLayout="column">
  <a class="view-icon-btn as-e"
     *ngIf="value.viewIconBtn"
     (click)="value.viewIconBtn.clickEvent?.event.emit()"
     [routerLink]="value.viewIconBtn.routePath ? value.viewIconBtn.routePath : []">
    <span class="material-icons clickable-blue">visibility</span>
  </a>
  <div *ngIf="value.title" fxFlex="{{value.sectionsSizes ? value.sectionsSizes.header : '0 0 0'}}" [ngClass]="value.headerAlign">
    <div fxLayout="row" style="width: 100%">
      <h2 fxFlex="99" class="card-title">{{ value.title | translate }}</h2>
      <div fxFlex="1"></div>
      <a fxFlex href="#"
         class="clickable-blue"
         *ngIf="value.editLink"
         [routerLink]="value.editLink.routePath ? value.editLink.routePath : []"
         (click)="value.editLink.clickEvent?.emit()">
        {{ 'edit' | translate }}
      </a>
    </div>
    <p class="card-subtitle"
       *ngIf="value.subTitle && isHtmlRegex.test(value.subTitle)"
       [ngClass]="{'centered': value.headerAlign === 'center'}"
       [innerHTML]="isHtmlRegex.test(value.subTitle) ? value.subTitle : ''">
    </p>
    <p class="card-subtitle"
       *ngIf="value.subTitle && !isHtmlRegex.test(value.subTitle)"
       [ngClass]="{'centered': value.headerAlign === 'center'}">
      {{ value.subTitle | translate }}
    </p>
  </div>
  <div fxFlex="{{value.sectionsSizes ? value.sectionsSizes.body : 0}}">
    <ng-content></ng-content>
    <ng-container *ngIf="value.blockLists && value.blockLists.layoutType === 'row'">
      <div *ngFor="let list of value.blockLists.lists">
          <h3>{{list.title}}</h3>
          <ol *ngIf="list.rowType === 'ol'">
            <li *ngFor="let olRowValue of list.values"
                [ngClass]="olRowValue ? 'list' : 'style-type-none'">{{(olRowValue | translate) || '&zwnj;'}}</li>
          </ol>
          <ul *ngIf="list.rowType === 'ul' || list.rowType === 'without-marker'"
              [ngClass]="{'style-type-none': list.rowType === 'without-marker'}">
            <li *ngFor="let ulRowValue of list.values"
                [ngClass]="ulRowValue ? 'list' : 'style-type-none'">{{(ulRowValue | translate) || '&zwnj;'}}</li>
          </ul>
      </div>
    </ng-container>
    <div *ngIf="value.blockLists && value.blockLists.layoutType === 'column'" fxLayout="row wrap">
      <ng-container *ngFor="let list of value.blockLists.lists; let i=index; let even=even;">
        <div fxFlex="48">
          <h3>{{list.title | translate}}</h3>
          <div *ngFor="let row of list.values">{{row}}</div>
        </div>
        <div *ngIf="even" fxFlex="4"></div>
      </ng-container>
    </div>
  </div>
  <antai-footer-with-btn
    fxFlex="{{value.sectionsSizes ? value.sectionsSizes.footer : 0}}"
    *ngIf="value.footerWithBtn"
    [value]="value.footerWithBtn">
  </antai-footer-with-btn>
</div>
