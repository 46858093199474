import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TypeService } from '../../../api/api-service-messagerie/models/type-service';
import { Sujet } from '../../../api/api-service-messagerie/models/sujet';
import { TypeOrganisationEnum } from '../../../api/api-gestion-acces-sgf/models/type-organisation-enum';
import { PieceJointeDto } from '../../../api/api-service-messagerie/models/piece-jointe-dto';
import { DownloadFileService } from '../../services/download-file.service';
import { SgfUploadFile } from '../antai-upload-file/antai-upload-file.model';
import { MessagerieService } from '../../../api/api-service-messagerie/services/messagerie.service';
import { MessageDto } from '../../../api/api-service-messagerie/models/message-dto';
import { MessagerieAdminService } from '../../../api/api-service-messagerie/services/messagerie-admin.service';
import { ConversationAdminDto } from '../../../api/api-service-messagerie/models/conversation-admin-dto';
import { ConversationDto } from '../../../api/api-service-messagerie/models/conversation-dto';
import { StatutConversation } from '../../../api/api-service-messagerie/models/statut-conversation';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class AntaiMessagerieService {

  constructor(private downloadFile: DownloadFileService,
              private messagerieService: MessagerieService,
              private messagerieAdminService: MessagerieAdminService) {
  }

  private nbConversationsATraiterSubject = new BehaviorSubject<number>(0);
  public nbConversationsATraiter = this.nbConversationsATraiterSubject.asObservable();

  private nbConversationsNonLuesSubject = new BehaviorSubject<number>(0);
  public nbConversationsNonLues = this.nbConversationsNonLuesSubject.asObservable();

  creerConversation(conversation: ConversationDto) {
    return this.messagerieService.postConversation({body: conversation});
  }

  creerConversationAdmin(conversation: ConversationAdminDto) {
    return this.messagerieAdminService.postConversationAdmin({body: conversation});
  }

  recupererConversation(idConversation: number) {
    return this.messagerieService.getConversation({idConversation: idConversation});
  }

  envoyerMessage(idConversation: number, messageDto: MessageDto) {
    return this.messagerieService.postMessage({idConversation: idConversation, body: messageDto});
  }

  modifierConversationAdmin(conversation: ConversationDto, organisations?: Array<string>) {
    return this.messagerieAdminService.modifierConversationAdmin({
      body: {
        ...conversation,
        organisations: organisations ?? ['']
      }
    });
  }

  recupererNombreConversationsParStatut(statutConversation: StatutConversation) {
    return this.messagerieAdminService.getNombreConversationParStatut({statutConversation: statutConversation});
  }

  rafraichirNombreConversationsATraiter() {
    this.recupererNombreConversationsParStatut(StatutConversation.ATraiter).subscribe({
      next: nombre => {
        this.nbConversationsATraiterSubject.next(nombre);
      }
    });
  }

  rafraichirNombreConversationsNonLues() {
    this.messagerieService.getNombreMessageNonLu().subscribe({
      next: nombre => {
        this.nbConversationsNonLuesSubject.next(nombre);
      }
    });
  }


  /**
   * Initialise les valeurs possibles pour 'sujet' et 'service'
   * Suit les changements du formulaire pour 'service' afin d'adapter les valeurs possibles de 'sujet'
   * @param formGroup contenant les FormControl 'sujet' et 'service'
   * @param serviceOptions tableau de valeurs possibles pour 'service'
   * @param sujetOptions tableau de valeurs possibles pour 'sujet'
   */
  gestionServiceEtSujet(formGroup: FormGroup, serviceOptions: TypeService[], sujetOptions: Sujet[]) {
    // initialisation service
    serviceOptions.length = 0;
    serviceOptions.push(...Object.values(TypeService));
    // Charger les informations de l'utilisateur depuis le localStorage
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser?.typeOrganisation === TypeOrganisationEnum.Collectivite) {
      const index = serviceOptions.indexOf(TypeService.Freeflow);
      if (index !== -1) {
        serviceOptions.splice(index, 1);
      }
    }
    // initialisation sujet
    const allSujets = Object.values(Sujet);
    sujetOptions.length = 0;
    sujetOptions.push(...allSujets);
    // ecoute des mises à jour de service
    formGroup?.get('service')?.valueChanges.subscribe((value) => {
      let sujets = [...allSujets];
      // filtres sujet
      if (value === TypeService.Fps) {
        sujets = sujets.filter(sujet => sujet !== Sujet.Annulation);
      }
      if (value === TypeService.Pve) {
        sujets = sujets.filter(sujet => sujet !== Sujet.FacturationFacture);
        sujets = sujets.filter(sujet => sujet !== Sujet.FacturationReversement);
        sujets = sujets.filter(sujet => sujet !== Sujet.FacturationAutre);
      }
      // reinitialisation sujet
      sujetOptions.length = 0;
      sujetOptions.push(...sujets);
    });
  }

  /**
   * (Dés)Active les FormControl de 'sujet' et 'service' selon la valeur actuelle de 'statut'
   * Initialise les options possibles pour 'statut' si passé en paramètre
   * @param formGroup contenant les FormControl 'sujet' et 'service'
   * @param statutConversation valeur actuelle de 'statut'
   * @param statutOptions tableau de valeurs possibles pour 'statut'
   */
  gestionStatut(formGroup: FormGroup, statutConversation: StatutConversation, statutOptions?: StatutConversation[]) {
    if (statutOptions) {
      statutOptions.length = 0;
      statutOptions.push(...Object.values(StatutConversation));
    }
    if (statutConversation === StatutConversation.Clos) {
      formGroup.get('service').disable();
      formGroup.get('sujet').disable();
    } else {
      formGroup.get('service').enable();
      formGroup.get('sujet').enable();
    }
  }

  /**
   * Transforme un SgfUploadFile en PieceJointeDto[]
   * @param sgfUploadFiles le gestionnaire de fichiers de swapart
   */
  async filesToPieceJointeDto(sgfUploadFiles: SgfUploadFile) {
    const piecesJointesDtos: PieceJointeDto[] = [];
    for (const pj of sgfUploadFiles.files) {
      const pjDto = {
        fichier: await this.downloadFile.getBase64(pj) as string,
        nom: pj.name,
        taille: pj.size,
      };
      piecesJointesDtos.push(pjDto);
    }
    return piecesJointesDtos;
  }

  /**
   * Fait télécharger une pièce jointe sur le navigateur de l'utilisateur
   * @param idPieceJointe id en BDD
   * @param nomFichier nom en BDD
   */
  telechargerPieceJointe(idPieceJointe: number, nomFichier: string) {
    this.messagerieService.getPieceJointe({idPieceJointe: idPieceJointe}).subscribe((pj) => {
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(pj);
      a.download = nomFichier;
      a.click();
    });
  }
}
