/* tslint:disable */
/* eslint-disable */

/**
 * Sujet de la conversation
 */
export enum Sujet {
  Conventionnement = 'CONVENTIONNEMENT',
  ProblemeTechnique = 'PROBLEME_TECHNIQUE',
  FacturationFacture = 'FACTURATION_FACTURE',
  FacturationReversement = 'FACTURATION_REVERSEMENT',
  FacturationAutre = 'FACTURATION_AUTRE',
  InformationRgpd = 'INFORMATION_RGPD',
  DemandeCertificat = 'DEMANDE_CERTIFICAT',
  InformationsCodeService = 'INFORMATIONS_CODE_SERVICE',
  Annulation = 'ANNULATION',
  QuestionService = 'QUESTION_SERVICE',
  Autre = 'AUTRE',
  ValidationCompte = 'VALIDATION_COMPTE',
  ConnexionAgc = 'CONNEXION_AGC',
  TraitementsTickets = 'TRAITEMENTS_TICKETS',
  DemandeAcces = 'DEMANDE_ACCES'
}
