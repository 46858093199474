import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpXsrfTokenExtractor } from '@angular/common/http';
import { Observable } from 'rxjs';

/**
 * Intercepteur copiant la valeur du cookie XCRF-TOKEN dans le header X-XSRF-TOKEN
 */
@Injectable()
export class HttpCsrfInterceptor implements HttpInterceptor {
  constructor(private tokenExtractor: HttpXsrfTokenExtractor) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let requestMethod: string = request.method;
    requestMethod = requestMethod.toLowerCase();

    if (requestMethod && (requestMethod === 'post' || requestMethod === 'delete' || requestMethod === 'put' )) {
      const headerName = 'X-XSRF-TOKEN';
      const token = this.tokenExtractor.getToken();
      if (token !== null && !request.headers.has(headerName)) {
        request = request.clone({ headers: request.headers.set(headerName, token) });
      }
    }
    return next.handle(request);
  }
}
