/* tslint:disable */
/* eslint-disable */

/**
 * Statut d'une conversation
 */
export enum StatutConversation {
  ATraiter = 'A_TRAITER',
  EnCours = 'EN_COURS',
  Suspendu = 'SUSPENDU',
  Clos = 'CLOS'
}
